

export const generateTimeOptions = (groupla) => {
    if (groupla === 'Floreasca') {
        console.log('sambata');
        return ['18:30', '19:30', '20:30'];
    } else {
        return ['16:00', '17:00', '18:00', '19:00', '20:00'];
    }
}

export const isDateLessThan30DaysAgo = (date) => {
    const today = new Date();
    const dateToCheck = new Date(date);
    const diffTime = Math.abs(today - dateToCheck);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays < 30;
}