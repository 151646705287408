import React from "react";
import "../../App.css";
import "./LandingPage.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import { useEffect, useState } from "react";
import TryButton from "../button/TryButton";
import logo from "../images/logo_text.png";
import { useNavigate } from "react-router-dom";
import { Modal, Row, Col } from "react-bootstrap";
import ChildRankingAdmin from "../child/ChildRankingAdmin";
import backgroundlevels from "../images/backgroundlevels.jpg";
import moment from "moment";
import DatePicker from "react-datepicker";
import { DropdownButton, Dropdown } from "react-bootstrap";
import EditIcon from "../images/edit_icon.svg";
import { generateTimeOptions } from "../../utils/utils.js";
import { Button } from 'react-bootstrap';
import CancelIcon from "../images/cancel.png";

function LandingPage() {
  const admin = window.localStorage.getItem("token") != null;
  const token = window.localStorage.getItem("token");
  let userType = window.localStorage.getItem("userType");
  let userLoc = window.localStorage.getItem("userLoc");
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [loginShow, setLoginShow] = useState(false);
  const [registerShow, setRegisterShow] = useState(false);
  const [adminRegister, setAdminRegister] = useState(false);
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [leaderboard, setLeaderboard] = useState([]);
  const [showIncome, setShowIncome] = useState(false);

  const [incomeTotal, setIncomeTotal] = useState(0);
  const [incomes, setIncomes] = useState([]);
  const [totalCard, setTotalCard] = useState(0);
  const [totalCash, setTotalCash] = useState(0);
  const [totalOP, setTotalOP] = useState(0);

  const [incomeDate, setIncomeDate] = useState(new Date());
  const [incomeLocation, setIncomeLocation] = useState("ANY");

  const [incomeIdx, setCurrentIncome] = useState(0);
  const [currentIncomeValues, setCurrentIncomeValues] = useState({});
  const [showEdit, setShowEdit] = useState(false);

  // Mitzu Presence Modal
  const [showMitzuModal, setShowMitzuModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [timeStart, settimeStart] = useState("");
  const [timeEnd, settimeEnd] = useState("");
    
  // State for managing the modal's visibility - delete
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [incomeToDelete, setIncomeToDelete] = useState(null);
  const [deleteConfirmationCount, setDeleteConfirmationCount] = useState(0);

  let isMobile = window.innerWidth <= 992;

  useEffect(() => {
    if (admin) {
      axios
        .get(process.env.REACT_APP_API_URL + "/auth/type", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resType) => {
          window.localStorage.setItem("userType", resType.data.userType);
          window.localStorage.setItem("userLoc", resType.data.location);
          userType = resType.data.userType;
          userLoc = resType.data.location;
        });
      checkLBModal();
    }
  }, [userType, userLoc]);

  function onXPress() {
    setShowLeaderboard(false);
    localStorage.removeItem("LBLoc");
  }

  function checkLBModal() {
    if (localStorage.getItem("LBLoc") === "Otopeni") {
      if (admin && (userType === "OWNER" || userLoc === "OTOPENI")) {
        axios
          .get(
            process.env.REACT_APP_API_URL +
              `/logs/leaderboardAdmin/` +
              `OTOPENI`
          )
          .then((res) => {
            setLeaderboard(res.data);
            //console.log(res.data);
          })
          .catch((err) => {
            //console.log(err);
          });
        setShowLeaderboard(true);
      }
    }

    if (localStorage.getItem("LBLoc") === "Dobroesti") {
      if (admin && (userType === "OWNER" || userLoc === "DOBROESTI")) {
        axios
          .get(
            process.env.REACT_APP_API_URL +
              `/logs/leaderboardAdmin/` +
              `DOBROESTI`
          )
          .then((res) => {
            setLeaderboard(res.data);
            //console.log(res.data);
          })
          .catch((err) => {
            //console.log(err);
          });
        setShowLeaderboard(true);
      }
    }
  }

  function editRequest(values) {
    axios
      .patch(process.env.REACT_APP_API_URL + "/payment/income", values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        alert(res.data.message);

        // Update income text
        let newIncomes = [...incomes];

        //console.log(res.data);

        newIncomes[incomeIdx].action = res.data.action;
        setIncomes(newIncomes);

        // await updateFunc();
      })
      .catch((e) => {
        alert(e.response.data);
        // updateFunc();
      });
    setShowEdit(false);
  }

  const handleMitzuPresence = async () => {
    if (!selectedLocation) {
      alert("Selectează locația!");
      return;
    }

    const data = {
      location: selectedLocation.toUpperCase(),
      date: moment(selectedDate).toISOString(),
      timeStart: timeStart,
      timeEnd: timeEnd,
    }

    console.log(data);

    axios.post(process.env.REACT_APP_API_URL + '/mitzuPresence', data,
      {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).then((res) => {
        alert(res.data);
        setShowMitzuModal(false);
    }).catch((err) => {
        alert(err.response.data);
    });
  };

    //Delete button
    const handleDeleteClick = (income) => {
        setIncomeToDelete(income); // Store the income to delete
        setShowDeleteModal(true);  // Show the confirmation modal
    };
    
    // Function to handle confirming the deletion - Delete button
   // Function to handle confirming the deletion
    const handleConfirmDelete = () => {
        if (deleteConfirmationCount < 1) {
            setDeleteConfirmationCount(deleteConfirmationCount + 1); // Increment the confirmation count
        } else {
            // Execute the delete action
            axios.post(`${process.env.REACT_APP_API_URL}/payment/income/${incomeToDelete._id}/delete`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
              }).then(res => {
                console.log(res.data.message);
                
                // Assuming the response contains the new state of isDeleted
                const newIsDeletedState = res.data.isDeleted; // or whatever the response structure is
                const updatedIncomes = incomes.map(inc => 
                    inc._id === incomeToDelete._id ? { ...inc, isDeleted: newIsDeletedState } : inc
                );
            
                setIncomes(updatedIncomes); // Update state
            }).catch(err => {
                console.log('Error deleting income:', err);
            });

            // Reset confirmation state
            setDeleteConfirmationCount(0);
            setShowDeleteModal(false); // Close the modal
        }
    };
  
  return (
    <div>
      <div className="wave-container">
        <div className="overlay" />
        <div className='page'>
        {/* <MouseParallaxContainer frictionX={0.1} frictionY={0.1}>
            <MouseParallaxChild factorX={0.1} factorY={0.1}>
                <img src={logo} className="logo" />
            </MouseParallaxChild>
        </MouseParallaxContainer> */}
        <img src={logo} className="logo" />
            <Formik
                initialValues={{ name: '' }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                    if(admin) {
                        navigate('/search', { state: {
                            search: values.name
                        }});
                        localStorage.setItem("group", "");
                        localStorage.setItem("time", "");
                        localStorage.setItem("selectedChildId", "");
                        localStorage.setItem("location", "");
                    }else
                        setShow(true);
                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <Field type="text" name="name" className="input-field"/>
                        <ErrorMessage name="email" component="div" />
                        <p>
                            <TryButton type="submit" disabled={isSubmitting} text="Caută" className="mx-2" />
                            { admin &&
                                <TryButton type="button" text="Overview" onClick={() => navigate("/overview")} className="mx-2"/>
                            }
                            { admin && userType === "OWNER" &&
                                <TryButton type="button" text="Logs" onClick={() => navigate("/logs")} className="mx-2"/>
                            }
                            { admin && (userType === "OWNER" || userType === "ADMIN") &&
                                <TryButton type="button" text="Venituri" onClick={() => {
                                    const selectedDate1 = userType === "ADMIN" ? new Date() : incomeDate;
                                    const locationSelect1 = userType === "ADMIN" ? userLoc : incomeLocation;
                                    axios.get(process.env.REACT_APP_API_URL + `/payment/income/` + locationSelect1 + "/" + moment(selectedDate1).format("DD-MM-yyyy"), {
                                        headers: {
                                            Authorization: `Bearer ${token}`
                                        }
                                    }).then(res => {
                                        setIncomeTotal(res.data.total);
                                        setIncomes(res.data.incomes);

                                        setTotalCard(res.data.paymentTotals[0]);
                                        setTotalCash(res.data.paymentTotals[1]);
                                        setTotalOP(res.data.paymentTotals[2]);
                                        //console.log(res.data);
                                    }).catch(err => {
                                        //console.log(err);
                                    });
                                    setShowIncome(true);
                                }} className="mx-3"/>
                            }
                            { admin && userType === "OWNER" &&
                                <TryButton type="button" text="Statistica" onClick={() => navigate("/statistics")} className="mx-2"/>
                            }
                            { /*
                              {admin && userType === "OWNER" && (
                              <TryButton
                                type="button"
                                text="Prezenta Mitzu"
                                onClick={() => setShowMitzuModal(true)}
                                className="mx-2"
                              />
                            )} */ }
                            <br/>
                            { !admin && <TryButton type="button" text="Login admin" onClick={() => setLoginShow(true)} className="mx-2"/> }
                            { admin && (userType === "ADMIN" || userType === "OWNER") &&
                                <TryButton type="button" text="Register" onClick={() => {setRegisterShow(true); setAdminRegister(false);}} className="mx-2"/>
                            }
                            { admin && userType === "OWNER" &&
                                <TryButton type="button" text="Register Admin" onClick={() => {setRegisterShow(true); setAdminRegister(true);}} className="mx-2"/>
                            }
                            { admin && <TryButton type="button" text="Logout" onClick={() => {window.localStorage.removeItem('token'); window.location.reload();}} className="mx-2"/> }
                            { admin &&
                                <TryButton type="button" text="Formular Otopeni" onClick={() => navigate("/form/OTOPENI")}/>
                            }
                            { admin &&
                                <TryButton type="button" text="Formular Dobroesti" onClick={() => navigate("/form/DOBROESTI")}/>
                            }
                            { admin && (userType === "OWNER" || userLoc === "DOBROESTI") &&
                                <TryButton type="button" text="Leaderboard Dobroesti" onClick={() => {
                                    axios.get(process.env.REACT_APP_API_URL + `/logs/leaderboardAdmin/` + `DOBROESTI`).then(res => {
                                        setLeaderboard(res.data);
                                        //console.log(res.data);
                                    }).catch(err => {
                                        //console.log(err);
                                    });
                                    setShowLeaderboard(true);
                                    localStorage.setItem("LBLoc", "Dobroesti");
                                }} className="mx-3"/>
                            }
                            { admin && (userType === "OWNER" || userLoc === "OTOPENI") &&
                                <TryButton type="button" text="Leaderboard Otopeni" onClick={() => {
                                    axios.get(process.env.REACT_APP_API_URL + `/logs/leaderboardAdmin/` + `OTOPENI`).then(res => {
                                        setLeaderboard(res.data);
                                        //console.log(res.data);
                                    }).catch(err => {
                                        //console.log(err);
                                    });
                                    setShowLeaderboard(true);
                                    localStorage.setItem("LBLoc", "Otopeni");
                                }} className="mx-3"/>
                            }
                        </p>
                    </Form>
                )}
            </Formik>
        </div>
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Nu sunteți logat!</Modal.Title>
            </Modal.Header>
            <Modal.Body>Pentru siguranța datelor copiilor și părinților, căutarea este valabilă doar pentru administratori. Pentru a accesa pagina cu prezențele copilului dvs. accesați link-ul de pe email-ul in care ați primit acordul din partea noastră sau contactați un administrator pentru mai multe detalii.</Modal.Body>
        </Modal>
        <Modal show={loginShow} onHide={() => setLoginShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Logare admin</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{
                        username: '',
                        password: ''
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        axios.post(process.env.REACT_APP_API_URL + "/auth/login", {
                            username: values.username,
                            password: values.password
                        }).then(res => {
                            window.localStorage.setItem("token", res.data);
                            axios.get(process.env.REACT_APP_API_URL + "/auth/type", {
                                headers: {
                                    Authorization: `Bearer ${res.data}`
                                }
                            }).then(resType => {
                                window.localStorage.setItem("userType", resType.data.userType);
                                window.localStorage.setItem("userLoc", resType.data.location);
                                userType = resType.data.userType;
                            });
                            setShow(false);
                            setLoginShow(false);
                            window.location.reload();
                        }).catch(err => {
                            alert(err.response.data);
                        });
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <Row>
                                <Col>
                                    <label htmlFor="name" className="form-label m-0 mx-2">Username</label>
                                    <Field type="text" name="username" className="input-field w-100"/>
                                    <ErrorMessage name="username" component="div" />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <label htmlFor="name" className="form-label m-0 mx-2">Parolă</label>
                                    <Field type="password" name="password" className="input-field w-100"/>
                                    <ErrorMessage name="password" component="div" />
                                </Col>
                            </Row>
                            <p>
                                <TryButton type="submit" disabled={isSubmitting} text="Login" />
                            </p>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
        <Modal show={registerShow} onHide={() => setRegisterShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Înregistrare cont</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{
                        name: '',
                        username: '',
                        password: '',
                        location: ''
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        let link = adminRegister ? "/auth/register/admin" : "/auth/register";
                        axios.post(process.env.REACT_APP_API_URL + link, {
                            name: values.name,
                            username: values.username,
                            password: values.password,
                            location: values.location.toUpperCase()
                        }, {
                            headers: {
                            'Authorization': `Bearer ${token}`
                            }
                        }).then(res => {
                            alert("Contul a fost creat cu succes!");
                            setShow(false);
                            setLoginShow(false);
                            setRegisterShow(false);
                            window.location.reload();
                        }).catch(err => {
                            alert(err.response.data);
                        });
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <Row>
                                <Col>
                                    <label htmlFor="name" className="form-label m-0 mx-2">Nume</label>
                                    <Field type="text" name="name" className="input-field w-100"/>
                                    <ErrorMessage name="name" component="div" />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <label htmlFor="name" className="form-label m-0 mx-2">Locație</label>
                                    <Field type="text" name="location" className="input-field w-100"/>
                                    <ErrorMessage name="location" component="div" />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <label htmlFor="name" className="form-label m-0 mx-2">Username</label>
                                    <Field type="text" name="username" className="input-field w-100"/>
                                    <ErrorMessage name="username" component="div" />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <label htmlFor="name" className="form-label m-0 mx-2">Parolă</label>
                                    <Field type="password" name="password" className="input-field w-100"/>
                                    <ErrorMessage name="password" component="div" />
                                </Col>
                            </Row>
                            <p>
                                <TryButton type="submit" disabled={isSubmitting} text="Înregistrare" />
                            </p>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
        <Modal show={showLeaderboard} onHide={() => onXPress()} fullscreen style={{backgroundImage: "url(" + backgroundlevels + ")", backgroundSize: "cover", backgroundPosition: "center center", backgroundRepeat: "no-repeat"}}>
            <div style={{backgroundImage: "url(" + backgroundlevels + ")"}} className='min-vh-100 scrollable text-light'>
                <Modal.Header closeButton className=''>
                    <Modal.Title>Leaderboard</Modal.Title>
                </Modal.Header>
                <Modal.Body className=''>
                    <div className={isMobile ? '' : 'w-50 m-auto'}>
                        <Row className='w-100 m-auto'>
                            <Col xs={2} sm={1} className='text-center'>
                                <h5>Loc</h5>                                            </Col>
                            <Col xs={4} sm={5} className='text-center'>
                                <h5>Nume</h5>                                            </Col>
                            <Col xs={3} className='text-center'>
                                <h5>Pct.</h5>                                            </Col>
                            <Col xs={3} className='text-center'>
                                <h5>Premiu</h5>
                            </Col>
                        </Row>
                        {leaderboard.map((lb, index) => <ChildRankingAdmin rank={index + 1} name={lb.name} points={lb.points} childRank={lb.rank} group={lb.group} time={lb.time} finalRankc={false} _id={lb._id} giftGiven={lb.giftGiven} location={lb.location}/>)}
                    </div>
                </Modal.Body>
            </div>
        </Modal>
        <Modal show={showIncome} onHide={() => setShowIncome(false)} fullscreen style={{backgroundImage: "url(" + backgroundlevels + ")", backgroundSize: "cover", backgroundPosition: "center center", backgroundRepeat: "no-repeat"}}>
            <div style={{backgroundImage: "url(" + backgroundlevels + ")"}} className='min-vh-100 scrollable text-light'>
                <Modal.Header closeButton className=''>
                    <Modal.Title>Venituri</Modal.Title>
                </Modal.Header>
                <Modal.Body className='page'>
                    <DropdownButton onSelect={(e) => { 
                        setIncomeLocation(e);
                        axios.get(process.env.REACT_APP_API_URL + `/payment/income/` + e + "/" + moment(incomeDate).format("DD-MM-yyyy"), {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        }).then(res => {
                            setIncomeTotal(res.data.total);
                            setIncomes(res.data.incomes);

                            setTotalCard(res.data.paymentTotals[0]);
                            setTotalCash(res.data.paymentTotals[1]);
                            setTotalOP(res.data.paymentTotals[2]);
                            //console.log(res.data);
                        }).catch(err => {
                            console.log(err);
                        });
                    }}title={userType === "ADMIN" ? userLoc : (incomeLocation === 'ANY' ? "Orice locație" : incomeLocation)} size="lg" variant="light">
                        {userType === "OWNER" ? (
                            <>
                                <Dropdown.Item eventKey="ANY">Orice locație</Dropdown.Item>
                                <Dropdown.Item eventKey="OTOPENI">Otopeni</Dropdown.Item>
                                <Dropdown.Item eventKey="DOBROESTI">Dobroesti</Dropdown.Item>
                            </>
                        ) : userType === "ADMIN" ? (
                            <Dropdown.Item eventKey={userLoc}>{userLoc}</Dropdown.Item>
                        ) : null}
                    </DropdownButton>
                    <div className='d-flex justify-content-center'>
                        <div className='w-50 mx-md-5 mx-3 py-4'>
                            {userType === "OWNER" && (<DatePicker
                                dateFormat="dd/MM/yyyy"
                                placeholderText="zi/lună/an"
                                className="form-control animate__shakeX"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                yearDropdownItemNumber={100}
                                onFocus={(e) => e.target.readOnly = true}
                                selected={(incomeDate && new Date(incomeDate)) || null}
                                onChange={val => {

                                    // Set the selected date accordingly
                                    setIncomeDate(val);
                                    axios.get(process.env.REACT_APP_API_URL + `/payment/income/` + incomeLocation + "/" + moment(val).format("DD-MM-yyyy"), {
                                        headers: {
                                            Authorization: `Bearer ${token}`
                                        }
                                    }).then(res => {
                                        setIncomeTotal(res.data.total);
                                        setIncomes(res.data.incomes);
                                        setTotalCard(res.data.paymentTotals[0]);
                                        setTotalCash(res.data.paymentTotals[1]);
                                        setTotalOP(res.data.paymentTotals[2]);

                                        //console.log(res.data);
                                    }).catch(err => {
                                        console.log(err);
                                    });
                                }}
                                onYearChange={(incomeDate) => setIncomeDate(incomeDate)}
                                onMonthChange={(incomeDate) => setIncomeDate(incomeDate)}
                                onWeekSelect={(incomeDate) => setIncomeDate(incomeDate)}
                                onDayChange={(incomeDate) => setIncomeDate(incomeDate)}
                                openToDate={incomeDate}
                            /> )}
                        </div>
                    </div>
                    <h2>Total: {incomeTotal} RON</h2>
                    <div class="payment-methods">
                        <span>Cash: {totalCash}</span>
                        <span>Card: {totalCard}</span>
                        <span>O.P.: {totalOP}</span>
                    </div>
                    <div className='mx-3'>
                    <>
                    {incomes.map((income, index) => 
    <Row key={JSON.stringify(income)} className='w-100 justify-content-center m-0 py-3'>
        <Col xs={4} md={3} className='border border-2 border-success text-break'>
            <p>{income.user}</p>
        </Col>
        <Col xs={8} md={3} className='border border-2 border-success'>
            <p>{moment(income.timestamp).format("DD/MM/yyyy - HH:mm:ss")}</p>
        </Col>
        <Col xs={12} md={4} className='border border-2 border-success d-flex align-items-center justify-content-between'>
            {income.amountModified && (
                <span style={{ color: 'yellow', marginLeft: '5px' }}>*</span>
            )}

            {income.action.split("\n").map((line, index) => (
                <p key={index} style={{ textDecoration: income.isDeleted ? 'line-through' : 'none' }}>
                    {line.split(" ").map((word, i) => {
                        if (word === 'CARD') {
                            return <span key={i} style={{ color: 'blue' }}>{word} </span>;
                        } else if (word === 'CASH') {
                            return <span key={i} style={{ color: 'green' }}>{word} </span>;
                        } else if (word === 'O.P.') {
                            return <span key={i} style={{ color: '#DAA520' }}>{word} </span>; // Dark Yellow
                        } else if (word === 'GRUPA') {
                            return <span key={i} style={{ color: 'aqua' }}>{word} </span>;
                        } else if (word === 'PT') {
                            return <span key={i} style={{ color: 'violet' }}>{word} </span>;
                        } else if (word === 'MINI-GRUPA') {
                            return <span key={i} style={{ color: 'chartreuse' }}>{word} </span>;
                        } else if (word === 'GRATIS') {
                            return <span key={i} style={{ color: 'orange' }}>{word} </span>;
                        } else {
                            return word + ' '; // Regular word
                        }
                    })}
                </p>
            ))}

            <div style={{ display: 'flex', alignItems: 'center' }}>
                <button className='edit-btn' onClick={() => {
                    setCurrentIncome(index);
                    setCurrentIncomeValues(income);
                    setShowEdit(true);
                }}>
                    <img src={EditIcon} className='edit-icon'/>
                </button>

                {/* Divider Line */}
                <div style={{ height: '30px', width: '2px', backgroundColor: 'green', margin: '0 10px' }} className="border border-success"></div>

                {admin && userType === "OWNER" && 
                    <button 
                        className='delete-btn' 
                        onClick={() => handleDeleteClick(income)} // Show modal on delete click
                        style={{ background: 'transparent', border: 'none', padding: 10, cursor: 'pointer' }}  // Transparent button
                    >
                        <img src={CancelIcon} alt="Cancel" style={{ width: '16px', height: '16px' }} />  {/* Icon size */}
                    </button> 
                }
            </div>
        </Col>
    </Row>
)}


                        {/* Modal for confirming deletion */}
                        <Modal 
                            show={showDeleteModal} 
                            onHide={() => {
                                setShowDeleteModal(false);
                                setDeleteConfirmationCount(0); // Reset counter on cancel
                            }} 
                            centered
                            onEntering={() => setDeleteConfirmationCount(0)} // Reset count when entering the modal
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Confirmare Stergere</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {deleteConfirmationCount === 0 
                                    ? "Esti sigur ca vrei sa ștergi acest venit?" 
                                    : "Confirmati din nou, doriti sa ștergeți acest venit?"}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => {
                                    setShowDeleteModal(false);
                                    setDeleteConfirmationCount(0); // Reset counter on cancel
                                }}>
                                    Anulare
                                </Button>
                                <Button variant="danger" onClick={handleConfirmDelete}>
                                    {deleteConfirmationCount === 0 ? "Șterge" : "Confirmă"}
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
      </div>

      {/* Edit modal */}
      <Modal show={showEdit} onHide={() => setShowEdit(false)} backdrop="static">
        <Modal.Header className='bg-black border border-danger' closeButton closeVariant='white'>
            <h1 className='text-light'>Editează Venit</h1>
        </Modal.Header>
        <Modal.Body className='bg-black border border-danger'>
            <Formik
                initialValues={{
                        amount: currentIncomeValues.amount,
                        paymentType: currentIncomeValues.paymentType,
                    }}
                validate={values => {
                    const errors = {};

                    if (!values.amount) {
                        errors.amount = 'Câmp obligatoriu!';
                    } else if(values.amount < 0) {
                        errors.amount = 'Valoare minimă 0!';
      }

      return errors;
    }}
    onSubmit={(values, { setSubmitting }) => {
      setSubmitting(false);

      // check if there has been an edit beforehand
      let edited = false;
      axios
        .get(
          process.env.REACT_APP_API_URL +
            "/payment/income/" +
            incomes[incomeIdx]._id,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          let newIncome = res.data;
          for (let key in newIncome) {
            if (newIncome[key] !== incomes[incomeIdx][key]) {
              console.log(key);
              edited = true;
              break;
            }
          }

          if (!edited) {
            values.id = incomes[incomeIdx]._id;

            editRequest(values);

            setShowEdit(false);
          } else {
            alert(
              "Venitul a fost editat între timp. Reîncărcați pagina și încercați din nou."
            );
          }
        })
        .catch((e) => {
          alert(e.response.data);
        });
    }}
  >
    {({ values, isSubmitting }) => (
      <Form className="m-auto w-100 attendee-form mt-3 px-2">
        <div className="mb-3">
          <label htmlFor="amount" className="form-label text-light">
            Sumă plată
          </label>
          <Field
            type="number"
            name="amount"
            placeholder="ex. 300"
            className="form-control animate__shakeX"
            id="amount"
          />
          <ErrorMessage
            name="amount"
            component="div"
            className="invalid-feedback"
          />
        </div>
        <div className="mb-3">
          <label
            htmlFor="paymentType"
            className="form-label text-light"
          >
            Metodă de plată
          </label>
          <Field
            as="select"
            name="paymentType"
            className="form-control animate__shakeX"
            id="paymentType"
          >
            <option value="TBD" selected>
              Alege metoda de plată
            </option>
            <option value="CASH">CASH</option>
            <option value="CARD">CARD</option>
            <option value="O.P.">O.P.</option>
          </Field>
          <ErrorMessage
            name="paymentType"
            component="div"
            className="invalid-feedback"
          />
        </div>

        <TryButton
          type="submit"
          disabled={isSubmitting}
          className="mt-4 m-auto d-block"
          text="Editează"
        />
      </Form>
    )}
  </Formik>
        </Modal.Body>
      </Modal>

      <Modal show={showMitzuModal} onHide={() => setShowMitzuModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Adauga prezenta Mitzu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-wrap mb-2 align-items-center">
            <p className="mb-0 mr-2 span">Locatia:</p>
            <DropdownButton
              onSelect={(e) => setSelectedLocation(e)}
              title={
                selectedLocation === ""
                  ? "Selectează locația"
                  : selectedLocation
              }
              size="md"
              variant="light"
              id="dropdown-location"
              className="ms-2"
            >
              <Dropdown.Item eventKey="Otopeni">Otopeni</Dropdown.Item>
              <Dropdown.Item eventKey="Dobroesti">Dobroești</Dropdown.Item>
            </DropdownButton>
          </div>
          <div className="d-flex mb-2 align-items-center">
            <p className="mb-0 me-4 span">Data:</p>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              placeholderText="zi/lună/an"
              className="form-control animate__shakeX"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              yearDropdownItemNumber={100}
              onFocus={(e) => (e.target.readOnly = true)}
              selected={(selectedDate && new Date(selectedDate)) || null}
              onChange={(val) => {
                setSelectedDate(val);
              }}
              onYearChange={(selectedDate) => setSelectedDate(selectedDate)}
              onMonthChange={(selectedDate) => setSelectedDate(selectedDate)}
              onWeekSelect={(selectedDate) => setSelectedDate(selectedDate)}
              onDayChange={(selectedDate) => setSelectedDate(selectedDate)}
              openToDate={selectedDate}
            />
          </div>

          <div className="d-flex flex-wrap mb-2 align-items-center">
            <p className="mb-0 me-3 span">De la:</p>
            <DropdownButton
              onSelect={(e) => settimeStart(e)}
              title={timeStart === "" ? "Selectează ora " : timeStart}
              size="md"
              variant="light"
              id="dropdown-time"
              className=""
            >
              {generateTimeOptions(selectedLocation).map((time) => {
                return <Dropdown.Item eventKey={time}>{time}</Dropdown.Item>;
              })}
            </DropdownButton>
            <p className="mb-0 ms-2 me-3 span">Pana la:</p>
            <DropdownButton
              onSelect={(e) => settimeEnd(e)}
              title={timeEnd === "" ? "Selectează ora " : timeEnd}
              size="md"
              variant="light"
              id="dropdown-time"
              className=""
            >
              {generateTimeOptions(selectedLocation).map((time) => {
                return <Dropdown.Item eventKey={time}>{time}</Dropdown.Item>;
              })}
            </DropdownButton>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <TryButton text="Anulează" onClick={() => setShowMitzuModal(false)} className="mx-3"/>
            <TryButton text="Adauga" className="greenButton" onClick={async () => {handleMitzuPresence()}}/>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default LandingPage;

/* sdjahdj */
