import '../../App.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Modal } from 'react-bootstrap';
import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import TryButton from '../button/TryButton';
import Attendance from './Attendance';
import moment from 'moment';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { DatePickerField } from '../form/DatePickerField';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import DatePicker from 'react-datepicker';
import './Child.css';
import ProgressBar from "@ramonak/react-progress-bar";
import level1 from "../images/level1.png";
import level2 from "../images/level2.png";
import level3 from "../images/level3.png";
import level4 from "../images/level4.png";
import level5 from "../images/level5.png";
import level6 from "../images/level6.png";
import level7 from "../images/level7.png";
import level8 from "../images/level8.png";
import backgroundlevels from "../images/backgroundlevels.jpg";
import Confetti from 'react-confetti';
import check from '../images/check.png';
import Reschedule from './Reschedule';
import {Spinner} from 'react-bootstrap';

function ChildPage({ children, child, closeFunc, updateFunc, dayDataByTime }) {
    const admin = window.localStorage.getItem("token") != null;
    const token = window.localStorage.getItem("token");
    let userType = window.localStorage.getItem("userType");
    const [showEdit, setShowEdit] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [actionText, setActionText] = useState('');
    const [action, setAction] = useState(() => {});
    const [fromDay, setFromDay] = useState('');
    const [toDay, setToDay] = useState('');
    const [fromTime, setFromTime] = useState('');
    const [toTime, setToTime] = useState('');
    const [date, setDate] = useState(new Date());
    const [showReschedule, setShowReschedule] = useState(false);
    const [showEditComments, setShowEditComments] = useState(false);
    const [showRanking, setShowRanking] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    const [formValues, setFormValues] = useState({
        name: child.name,
        birthday: moment(child.birthday).format('YYYY-MM-DD'),
        parentName: child.parentName,
        phoneNumber: child.phoneNumber,
        email: child.email,
        startDate: moment(child.startDate).format('YYYY-MM-DD'),
        paymentStatus: child.paymentStatus,
        group: child.group,
        time: child.time,
        level: child.level,
        attendanceNo: child.attendanceNo,
        comments: child.comments,
        coachcomments: child.coachcomments,
        validated: child.validated,
        hidden: child.hidden,
        membership: child.membership,
        location: child.location,
        amount: child.amount,
        paymentType: child.paymentType,
    });

    let attendanceToday = false;
    let reArray = child.reschedule.split("-");
    let isMobile = window.innerWidth <= 992;
    const minRanks = [0, 40, 105, 245, 370, 605, 910, 1200, 1200];
    const ranks = [level1, level2, level3, level4, level5, level6, level7, level8, level8];
    const prizes = ["Niciun premiu", "Bomboană specială + Diplomă", "Clip special de la Mitzuu", "Primești Merch Special", "Filmezi cu Mitzuu Story/TikTok", "Super Mystery Box", "Ședință VIP Cu Mitzuu" , "Telefon Nou!", "Telefon Nou!"];
    const confettiRef = useRef(null);
    const [confettiWidth, setConfettiWidth] = useState(0);
    const [confettiHeight, setConfettiHeight] = useState(0);
    const [confettiOn, setConfettiOn] = useState(false);
    const [logs, setLogs] = useState([]);
    const [showLogs, setShowLogs] = useState(false);
    const [logsStartDate, setLogsStartDate] = useState(new Date().setHours(0, 0, 0, 0));
    const [logsEndDate, setLogsEndDate] = useState(new Date().setHours(23, 59, 59, 999));

    useEffect(() => {
        child.attendance.forEach((att) => {
            if(moment(att.date).format("DD/MM/yyyy") === moment().format("DD/MM/yyyy")) {
                attendanceToday = true;
            }
        });
        isMobile = window.innerWidth <= 768;
        setConfettiWidth(confettiRef.current.clientWidth);
        setConfettiHeight(confettiRef.current.clientHeight);
        if(logs.length === 0) {
            axios.get(process.env.REACT_APP_API_URL + '/logs/logs/' + child._id, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                setLogs(response.data);
            }).catch((error) => {
                console.log(error);
            });
        }
    }, [JSON.stringify(child), fromDay, toDay, fromTime, toTime]);

    async function addAttendance(status, id, substractPoints=true) {
        setShowLoading(true);
        await axios.post(process.env.REACT_APP_API_URL + '/attendance/add', {
            status: status,
            id: id,
            substractPoints: substractPoints
        }, {
            headers: {
              'Authorization': `Bearer ${token}` 
            }
        }).then((res) => {
            alert(res.data);
        }).catch((e) => {
            alert(e.response.data);
        });

        axios.get(process.env.REACT_APP_API_URL + '/logs/logs/' + child._id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            setLogs(response.data);
        }).catch((error) => {
            console.log(error);
        });

        await updateFunc();
        setShowLoading(false);
    }

    async function addGift(status, id) {
        await axios.post(process.env.REACT_APP_API_URL + '/points/addGift', {
            status: status,
            id: id
        }, {
            headers: {
              'Authorization': `Bearer ${token}` 
            }
        }).then((res) => {
            alert(res.data);
        }).catch((e) => {
            alert(e.response.data);
        });

        axios.get(process.env.REACT_APP_API_URL + '/logs/logs/' + child._id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            setLogs(response.data);
        }).catch((error) => {
            console.log(error);
        });

        await updateFunc();
    }

    async function scrollToTop() {
        setTimeout(function () {
            window.scrollTo(0, 1);
        },2);
    }

    async function resetAttendance() {
        await axios.post(process.env.REACT_APP_API_URL + '/attendance/attendance/reset', {
            id: child._id
        }, {
            headers: {
              'Authorization': `Bearer ${token}` 
            }
        }).then((res) => {
            alert(res.data);
        }).catch((e) => {
            alert(e.response.data);
        });

        axios.get(process.env.REACT_APP_API_URL + '/logs/logs/' + child._id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            setLogs(response.data);
        }).catch((error) => {
            console.log(error);
        });

        await updateFunc();
    }

    function dayCode(day) {
		switch(day) {
			case 'Lu':
				return "Luni";
			case 'Ma':
				return "Marți";
			case 'Mi':
				return "Miercuri";
			case 'Jo':
				return "Joi";
			case 'Vi':
				return "Vineri";
			case 'Sa':
				return "Sâmbătă";
			default:
				return "Oricare";
		}
	}

    const membershipColors = {
        GRUPA: 'aqua', 
        MINIGRUPA: 'chartreuse',
        PT: 'violet',
        GRATIS: 'orange'
    };

    const generateTimeOptions = (groupla) => {
        if (groupla === 'Sa') {
            return ['11:00', '12:00', '13:00', '14:00', '15:00'];
        } else {
            return ['16:00', '17:00', '18:00', '19:00', '20:00'];
        }
    }

    async function goldenBuzz(id) {
        await axios.post(process.env.REACT_APP_API_URL + '/points/buzz', {
            id: id
        }, {
            headers: {
              'Authorization': `Bearer ${token}` 
            }
        }).then((res) => {
            alert(res.data);
            setConfettiOn(true);
            setTimeout(() => {
                setConfettiOn(false);
            }, 10000);
        }).catch((e) => {
            alert(e.response.data);
        });

        axios.get(process.env.REACT_APP_API_URL + '/logs/logs/' + child._id, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            setLogs(response.data);
        }).catch((error) => {
            console.log(error);
        });

        await updateFunc();
    }

    function editRequest(values) {
        setShowLoading(true);
        axios.patch(process.env.REACT_APP_API_URL + '/attendees/', values, {
            headers: { 'Authorization': `Bearer ${token}` }
        }).then(async (res) => {
            setShowEdit(false);
            await updateFunc(); 
            alert(res.data);
            setShowLoading(false);
            console.log("!!!!S-A TERMINA EDITU");
        }).catch((e) => {
            alert(e.response.data);
            updateFunc()
        }).finally(() => {
        });
    }

    // Activate loading screen for a certain amount of time
    const showLoadingScreen = async (seconds, action) => {
        setShowLoading(true);
        await new Promise(resolve => setTimeout(resolve, seconds * 1000));
        action();
        setShowLoading(false);
    };


    return(
        scrollToTop(),
        <div className="page no-padding-top" ref={confettiRef}>

            {/* Loading Screen */}
            {showLoading &&
                <div className='d-flex justify-content-center align-items-center' style={{position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: '1060', display: 'none', backdropFilter: 'blur(1px)'}}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            }

            <Confetti numberOfPieces={confettiOn ? 1000 : 0} initialVelocityX={0} initialVelocityY={{min: -10, max: 7}} width={confettiWidth} height={confettiHeight} className='overflow-hidden'/>
            <div className="d-flex justify-content-between">
                <TryButton type="button" className='mx-2 mx-md-3 my-2' onClick={() => setShowLogs(true)} text="Logs"/>
                <TryButton className='me-3 my-3' onClick={closeFunc} text="Revenire" />
            </div>

            <h1 className='m-auto pb-5 fw-bold fst-italic'>{child.name}</h1>
            <Row className='text-center mx-md-5 mx-4'>
                <Col md={6}>
                    <div className='white-header'>Date generale</div>
                    { admin && <p>Data nașterii: {moment(child.birthday).format("DD/MM/yyyy")}</p>}
                    <p>Nume părinte: {child.parentName}</p>
                    { userType !== "COACH" && <p>Email: {child.email}</p>}
                    { userType !== "COACH" && <p>Nr. de telefon: {child.phoneNumber}</p>}
                    <p>Data de început: {moment(child.startDate).format("DD/MM/yyyy")}</p>
                    <p>Stare plată: {child.paymentStatus}</p>
                    <p>
                        Suma platita: {child.amount} 
                        {child.amountModified &&
                            <span style={{color: 'yellow', marginLeft: '5px'}}>
                                *
                            </span>
                        } {" "} {child.paymentType}
                    </p>
                </Col>
                <Col md={6}>
                    <div className='white-header'>Date administrative</div>
                    <p>Grupa: {child.group}</p>
                    <p>Ora: {child.time}</p>
                    <p>Nivel: {child.level}</p>
                    <p>Sedințe rămase:&nbsp;
                        <span style={{ color: child.attendanceNo === 0 ? 'red' : child.attendanceNo === 1 ? 'yellow' : 'green' }}>
                        {child.attendanceNo}
                        </span>
                    </p>
                    <p>Absențe: {child.missed}</p>
                    <p>Abonament: {child.membership}</p>
                    <p>Locatie: {child.location}</p>
                    <p>Reprogramare: {child.reschedule != '' ? reArray[0] +  "\xA0\xA0-> \xA0\xA0" +  reArray[1] : "Nu"}</p>
                    <p>Dată reprogramare: {child.rescheduleDate != null ? moment(child.rescheduleDate).format("DD/MM/yyyy") : "Nu"}</p>
                </Col>
                <Col xs={0} md={3}>
                </Col>
                <Col xs={12} md={6}>
                    <img src={ranks[child.rank - 1]} alt="level1" onClick={() => setShowRanking(true)} className='large-rank'/>
                    <h1 className='mb-3'>Level {child.rank}</h1>
                    <div className='d-flex w-100 justify-content-center mb-md-3 mb-5'>
                        <div className='position-relative'>
                            <img src={ranks[child.rank - 1]} alt={"level" + child.rank} className='small-rank'/>
                            <p className='rank-label text-center '>{prizes[child.rank - 1]}</p>
                        </div>
                        <ProgressBar completed={child.points.toString()} customLabel={child.points.toString() + "/" + minRanks[child.rank]} maxCompleted={minRanks[child.rank]} height='3vh' width='100%' margin='auto' className='d-flex w-100' labelClassName='progress-label-main' barContainerClassName='progress-container-main'/>
                        <div className='position-relative'>
                            <img src={ranks[child.rank]} alt={"level" + (child.rank + 1)} className='small-rank'/>
                            <p className='rank-label text-center '>{prizes[child.rank]}</p>
                        </div>
                    </div>
                </Col>
                <Col xs={0} md={3}>
                </Col>
                <Col xs={12}>
                    <div className='white-header'>Comentarii</div>
                    <p>{child.comments != "" ? child.comments : "Nu sunt comentarii."}</p>
                </Col>
                <Col xs={12}>
                    <div className='white-header'>Comentarii Antrenor</div>
                    <p>{child.coachcomments != "" ? child.coachcomments : "Antrenorul nu are comentarii."}</p>
                </Col>
                { child.attendance.length > 0 &&
                    <Col>
                        <div className='white-header'>Catalog</div>
                        <div className='d-flex flex-wrap mb-3 justify-content-md-start justify-content-center'>
                            {child.attendance.map((att) => {
                                if(att.reschedule){
                                    return <Reschedule key={JSON.stringify(att)} reschedule={att} admin={admin} handleUpdate={updateFunc}/>;
                                } else{   
                                    return <Attendance key={JSON.stringify(att)} attendance={att} handleUpdate={updateFunc} admin={admin} attendanceMembership = {child.membership} />;
                                }
                            })}
                        </div>
                    </Col>
                }
            </Row>
            {admin && 
                <div>
                    <TryButton type="button" className='m-auto mx-2 mx-md-3 my-2 d-inline' onClick={() => {
                            if(attendanceToday) {
                                setShowConfirmation(true);
                                setActionText("adăugați INCĂ o prezență astăzi (ați mai adăugat o prezență/absență astăzi)");
                                setAction(() => () => {addAttendance('PRESENT', child._id); setShowConfirmation(false);});
                            } else {
                                setShowConfirmation(true);
                                setActionText("adăugați prezență astăzi");
                                setAction(() => () => {addAttendance('PRESENT', child._id); setShowConfirmation(false);});
                            }
                        }} text="Adaugă prezență"/>
                    <TryButton type="button" className='m-auto mx-2 mx-md-3 my-2 d-inline' onClick={() => {
                            if(attendanceToday) {
                                setShowConfirmation(true);
                                setActionText("adăugați ÎNCĂ o absență astăzi (ați mai adăugat o prezență/absență astăzi)");
                                setAction(() => () => {addAttendance('MISSED', child._id); setShowConfirmation(false);})
                            } else {
                                setShowConfirmation(true);
                                setActionText("adăugați absență astăzi");
                                setAction(() => () => {addAttendance('MISSED', child._id); setShowConfirmation(false);})
                            }
                        }} text="Adaugă absență"/>
                    { userType !== "COACH" && (
                        <TryButton type="button" className='m-auto mx-2 mx-md-3 my-2 d-inline' onClick={() => {
                                if(attendanceToday) {
                                    setShowConfirmation(true);
                                    setActionText("adăugați ÎNCĂ o absență astăzi (ați mai adăugat o prezență/absență astăzi)");
                                    setAction(() => () => {addAttendance('MISSED', child._id, false); setShowConfirmation(false);})
                                } else {
                                    setShowConfirmation(true);
                                    setActionText("adăugați absență astăzi");
                                    setAction(() => () => {addAttendance('MISSED', child._id, false); setShowConfirmation(false);})
                                }
                            }} text="Adaugă absență fara depunctare"/>
                    )}
                    <TryButton type="button" className='m-auto mx-2 mx-md-3 my-2 d-inline border border-warning' onClick={() => {
                                setShowConfirmation(true);
                                setActionText("dați golden buzz copilului");
                                setAction(() => () => {goldenBuzz(child._id); setShowConfirmation(false);});
                        }} text="Golden Buzz"/>
                    <TryButton type="button" className='m-auto mx-2 mx-md-3 mt-2 mb-3 d-inline' onClick={() => {
                        if(userType === 'COACH') {
                            setShowEditComments(true); setShowConfirmation(false);
                        } else {
                            setShowEdit(true); setShowConfirmation(false);
                        }
                    }} text="Editează"/>
                    <TryButton type="button" className='m-auto mx-2 mx-md-3 my-2 d-inline' onClick={() => setShowReschedule(true)} text="Reprogramează"/>
                    <TryButton text="Șterge reprogramare" onClick={async () => {
                                setShowConfirmation(true);
                                setActionText("ștergeți reprogramarea copilului");
                                setAction(() => async () => {
                                    axios.patch(process.env.REACT_APP_API_URL + '/attendance/reschedule', {
                                        id: child._id,
                                        reschedule: "",
                                        rescheduleDate: null
                                    },{
                                        headers: {
                                            Authorization: `Bearer ${token}`
                                        }
                                    }).then((res) => {
                                       alert(res.data);
                                       setShowReschedule(false);
                                    }).catch((err) => {
                                        alert(err.response.data);
                                        setShowReschedule(false);
                                    });
                                    await updateFunc();
                                    setShowConfirmation(false);
                                });
                            }}/>
                    <TryButton type="button" className='m-auto mx-2 mx-md-3 my-2 d-inline' onClick={() => { setShowConfirmation(true); setActionText("resetați prezențele și absențele copilului, chiar dacă nu mai pot fi recuperate"); setAction(() => () => { setActionText("RESETAȚI tot catalogul copulului? Această acțiune NU ESTE REVERSIBILĂ și prezențele și absențele NU POT FI RECUPERATE! Sigur doriți acest lucru"); setAction(() => () => {resetAttendance(); setShowConfirmation(false);});})}} text="Resetează catalogul"/>
                </div>
            }
            <Modal show={showLogs} onHide={() => setShowLogs(false)} >
                <Modal.Header closeButton className='bg-dark'>
                    <Modal.Title className='text-light'>Logs</Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-dark'>
                    <div className='d-flex justify-content-between align-items-center px-5'>
                        <p className='text-light'>DE LA:</p>
                        <div className='w-50 mx-md-5 px-3 py-4'>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                placeholderText="zi/lună/an"
                                className="form-control animate__shakeX"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                yearDropdownItemNumber={100}
                                onFocus={(e) => e.target.readOnly = true}
                                selected={(logsStartDate && new Date(logsStartDate)) || null}
                                onChange={val => {
                                    setLogsStartDate(val.setHours(0, 0, 0, 0));
                                }}
                                onYearChange={(date) => setLogsStartDate(date.setHours(0, 0, 0, 0))}
                                onMonthChange={(date) => setLogsStartDate(date.setHours(0, 0, 0, 0))}
                                onWeekSelect={(date) => setLogsStartDate(date.setHours(0, 0, 0, 0))}
                                onDayChange={(date) => setLogsStartDate(date.setHours(0, 0, 0, 0))}
                                openToDate={logsStartDate}
                            />
                        </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center px-5'>
                        <p className='text-light'>PANA LA:</p>
                        <div className='w-50 mx-md-5 px-3 py-4'>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                placeholderText="zi/lună/an"
                                className="form-control animate__shakeX"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                yearDropdownItemNumber={100}
                                onFocus={(e) => e.target.readOnly = true}
                                selected={(logsEndDate && new Date(logsEndDate)) || null}
                                onChange={val => {
                                    setLogsEndDate(val.setHours(23, 59, 59, 999));
                                }}
                                onYearChange={(date) => setLogsEndDate(date.setHours(23, 59, 59, 999))}
                                onMonthChange={(date) => setLogsEndDate(date.setHours(23, 59, 59, 999))}
                                onWeekSelect={(date) => setLogsEndDate(date.setHours(23, 59, 59, 999))}
                                onDayChange={(date) => setLogsEndDate(date.setHours(23, 59, 59, 999))}
                                openToDate={logsEndDate}
                            />
                        </div>
                    </div>
                    {<div className='mx-3'>
                        {logs.filter((log) => new Date(log.timestamp) > logsStartDate && new Date(log.timestamp) < logsEndDate).map((log) => 
                            <Row key={JSON.stringify(log)} className='w-100 justify-content-center m-0 py-3 text-light bg-dark'>
                                <Col xs={4} md={3} className='border border-2 border-warning text-break'>
                                    <p>{log.user}</p>
                                </Col>
                                <Col xs={8} md={3} className='border border-2 border-warning'>
                                    <p>{moment(log.timestamp).format("DD/MM/yyyy - HH:mm:ss")}</p>
                                </Col>
                                <Col xs={12} md={4} className='border border-2 border-warning'>
                                    {log.action.split("\n").map((line) => <p>{line}</p>)}
                                </Col>
                            </Row>
                        )} 
                        </div>}
                </Modal.Body>
            </Modal>
            <Modal show={showRanking} onHide={() => setShowRanking(false)} fullscreen style={{backgroundImage: "url(" + backgroundlevels + ")", backgroundSize: "cover", backgroundPosition: "center center", backgroundRepeat: "no-repeat"}}>
                <div style={{backgroundImage: "url(" + backgroundlevels + ")"}} className='min-vh-100 scrollable text-light'>
                    <Modal.Header closeButton className=''>
                        <Modal.Title>Detalii rank</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className=''>
                        <div className='d-flex justify-content-center'>
                            <TryButton type="button" className='m-auto mx-2 mx-md-3 mt-2 d-inline' onClick={() => {
                                            setShowConfirmation(true);
                                            setActionText("adaugati cadoul \"" + prizes[child.giftGiven + 1] + "\"");
                                            setAction(() => () => {addGift('PRESENT', child._id); setShowConfirmation(false);});
                                    }} text="Adaugă cadou"/>
                            <TryButton type="button" className='m-auto mx-2 mx-md-3 mt-2 d-inline' onClick={() => {
                                            setShowConfirmation(true);
                                            setActionText("Sterge cadoul cu numarul \"" + prizes[child.giftGiven] + "\"");
                                            setAction(() => () => {addGift('MISSED', child._id); setShowConfirmation(false);});
                                    }} text="Sterge cadou"/>
                        </div>
                        <div className={isMobile ? 'mt-4' : 'd-flex justify-content-center mt-md-5'}>
                            <div className='d-flex justify-content-center mb-5 pb-5'>
                                <div className='position-relative'>
                                    <img src={level1} alt="level1" className='medium-rank'/>
                                    <p className='rank-label text-center '>{prizes[0]}</p>
                                </div>
                                <ProgressBar completed={child.rank >= 1 ? child.points.toString() : "0"} customLabel={ child.points.toString() + "/" + minRanks[1]} maxCompleted={minRanks[1]} height={isMobile ? '3.5vw' : '1.5vw'} width={isMobile ? '22.5vw' : '10vw'} margin='auto' className={'d-flex px-1'} labelClassName='progress-label' barContainerClassName='progress-container'/>
                                <div className='position-relative'>
                                    { child.giftGiven >= 1 && <img src={check} alt="check" className='position-absolute gift-given'/>}
                                    <img src={level2} alt="level2" className='medium-rank'/>
                                    <p className='rank-label text-center '>{prizes[1]}</p>
                                </div>
                                <ProgressBar completed={child.rank >= 2 ? child.points.toString() : "0"} customLabel={ child.points.toString() + "/" + minRanks[2]} maxCompleted={minRanks[2]} height={isMobile ? '3.5vw' : '1.5vw'} width={isMobile ? '22.5vw' : '10vw'} margin='auto' className={'d-flex px-1'} labelClassName='progress-label' barContainerClassName='progress-container'/>
                                <div className='position-relative'>
                                    { child.giftGiven >= 2 && <img src={check} alt="check" className='position-absolute gift-given'/>}
                                    <img src={level3} alt="level3" className='medium-rank'/>
                                    <p className='rank-label text-center '>{prizes[2]}</p>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center mb-5 pb-5'>
                                { isMobile && 
                                    <div className='position-relative'>
                                        { child.giftGiven >= 2 && <img src={check} alt="check" className='position-absolute gift-given'/>}
                                        <img src={level3} alt="level3" className='medium-rank'/>
                                        <p className='rank-label text-center '>{prizes[2]}</p>
                                    </div>
                                }
                                <ProgressBar completed={child.rank >= 3 ? child.points.toString() : "0"} customLabel={ child.points.toString() + "/" + minRanks[3]} maxCompleted={minRanks[3]} height={isMobile ? '3.5vw' : '1.5vw'} width={isMobile ? '22.5vw' : '10vw'} margin='auto' className={'d-flex px-1'} labelClassName='progress-label' barContainerClassName='progress-container'/>
                                <div className='position-relative'>
                                    { child.giftGiven >= 3 && <img src={check} alt="check" className='position-absolute gift-given'/>}
                                    <img src={level4} alt="level4" className='medium-rank'/>
                                    <p className='rank-label text-center '>{prizes[3]}</p>
                                </div>
                                <ProgressBar completed={child.rank >= 4 ? child.points.toString() : "0"} customLabel={ child.points.toString() + "/" + minRanks[4]} maxCompleted={minRanks[4]} height={isMobile ? '3.5vw' : '1.5vw'} width={isMobile ? '22.5vw' : '10vw'} margin='auto' className={'d-flex px-1'} labelClassName='progress-label' barContainerClassName='progress-container'/>
                                <div className='position-relative'>
                                    { child.giftGiven >= 4 && <img src={check} alt="check" className='position-absolute gift-given'/>}
                                    <img src={level5} alt="level5" className='medium-rank'/>
                                    <p className='rank-label text-center '>{prizes[4]}</p>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center mb-5 pb-5'>
                                { isMobile &&
                                    <div className='position-relative'>
                                        { child.giftGiven >= 4 && <img src={check} alt="check" className='position-absolute gift-given'/>}
                                        <img src={level5} alt="level5" className='medium-rank'/>
                                        <p className='rank-label text-center '>{prizes[4]}</p>
                                    </div>
                                }
                                <ProgressBar completed={child.rank >= 5 ? child.points.toString() : "0"} customLabel={ child.points.toString() + "/" + minRanks[5]} maxCompleted={minRanks[5]} height={isMobile ? '3.5vw' : '1.5vw'} width={isMobile ? '22.5vw' : '10vw'} margin='auto' className={'d-flex px-1'} labelClassName='progress-label' barContainerClassName='progress-container'/>
                                <div className='position-relative'>
                                    { child.giftGiven >= 5 && <img src={check} alt="check" className='position-absolute gift-given'/>}
                                    <img src={level6} alt="level6" className='medium-rank'/>
                                    <p className='rank-label text-center '>{prizes[5]}</p>
                                </div>
                                <ProgressBar completed={child.rank >= 6 ? child.points.toString() : "0"} customLabel={ child.points.toString() + "/" + minRanks[6]} maxCompleted={minRanks[6]} height={isMobile ? '3.5vw' : '1.5vw'} width={isMobile ? '22.5vw' : '10vw'} margin='auto' className={'d-flex px-1'} labelClassName='progress-label' barContainerClassName='progress-container'/>
                                <div className='position-relative'>
                                    { child.giftGiven >= 6 && <img src={check} alt="check" className='position-absolute gift-given'/>}
                                    <img src={level7} alt="level7" className='medium-rank'/>
                                    <p className='rank-label text-center '>{prizes[6]}</p>
                                </div>
                            </div>
                            
                            <div className='d-flex justify-content-center mb-5 pb-5'>
                                { isMobile &&
                                    <div className='position-relative'>
                                        { child.giftGiven >= 6 && <img src={check} alt="check" className='position-absolute gift-given'/>}
                                        <img src={level7} alt="level7" className='medium-rank'/>
                                        <p className='rank-label text-center '>{prizes[6]}</p>
                                    </div>
                                }
                                
                                <ProgressBar completed={child.rank >= 7 ? child.points.toString() : "0"} customLabel={ child.points.toString() + "/" + minRanks[7]} maxCompleted={minRanks[8]} height={isMobile ? '3.5vw' : '1.5vw'} width={isMobile ? '22.5vw' : '10vw'} margin='auto' className={'d-flex px-1'} labelClassName='progress-label' barContainerClassName='progress-container'/>
                                <div className='position-relative'>
                                    { child.giftGiven >= 7 && <img src={check} alt="check" className='position-absolute gift-given'/>}
                                    <img src={level8} alt="level8" className='medium-rank'/>
                                    <p className='rank-label text-center '>{prizes[7]}</p>
                                </div>
                            </div>
                        </div>
                        <div className={isMobile ? 'w-100 m-auto px-2' : 'w-50 m-auto'}>
                            <h1 className='text-center text-warning mb-5 dropshadow-text'>Cum fac puncte?</h1>
                            <div className='mb-5'>
                                <p> 1 Prezență la TRY Academy = <span className="dropshadow-textgreen">+5</span> puncte</p>
                                <p>1 Absență = <span className="dropshadow-textred">-3</span> puncte</p>
                                <p>Reprogramare = <span className="dropshadow-textgreen">+2</span> puncte(<span className="dropshadow-textred">-3</span> absență <span className="dropshadow-textgreen">+5</span> prezență)</p>
                                <p><span className="dropshadow-textyellow">Antrenamentul de aur = +10 puncte</span></p>
                                <p>1 Lună Vechime la TRY Academy = +10 puncte</p>
                            </div>
                            <h4>Deci vino la antrenamente, fă puncte și bucură-te de cele mai tari premii EVER!</h4>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
            <Modal show={showReschedule} onHide={() => setShowReschedule(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Reprogramare</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='mb-3 '>ABONAMENT: <span style={{color: membershipColors[child.membership]}}>{child.membership}</span></p>
                    <p className='mr-2'>A lipsit la sedinta:</p>
                    <div className='d-flex flex-wrap mb-2'>
                        <DropdownButton onSelect={(e) => setFromDay(e)} title={fromDay === '' ? "Selectează ziua" : dayCode(fromDay)} size="lg" variant="light">
                            <Dropdown.Item eventKey="">Niciuna</Dropdown.Item>
                            {child.group.split("-").map((group) => {
                                switch(group) {
                                    case "Lu" :
                                        return <Dropdown.Item eventKey="Lu">Luni</Dropdown.Item>
                                    case "Ma" :
                                        return <Dropdown.Item eventKey="Ma">Marți</Dropdown.Item>
                                    case "Mi" :
                                        return <Dropdown.Item eventKey="Mi">Miercuri</Dropdown.Item>
                                    case "Jo" :
                                        return <Dropdown.Item eventKey="Jo">Joi</Dropdown.Item>
                                    case "Vi" :
                                        return <Dropdown.Item eventKey="Vi">Vineri</Dropdown.Item>
                                    case "Sa" :
                                        return <Dropdown.Item eventKey="Sa">Sâmbătă</Dropdown.Item>
                                    default:
                                        return <Dropdown.Item eventKey="Sa">Sâmbătă</Dropdown.Item>
                                }
                            })}
                        </DropdownButton>
                        <DropdownButton onSelect={(e) => setFromTime(e)} title={fromTime === '' ? "Selectează ora " : fromTime} size="lg" variant="light">
                            <Dropdown.Item eventKey="">Niciuna</Dropdown.Item>
                            {child.time.split("-").map((time) => {
                                switch(time) {
                                    case "10:00" :
                                        return <Dropdown.Item eventKey="10:00">10:00</Dropdown.Item>
                                    case "11:00" :
                                        return <Dropdown.Item eventKey="11:00">11:00</Dropdown.Item>
                                    case "12:00" :
                                        return <Dropdown.Item eventKey="12:00">12:00</Dropdown.Item>
                                    case "13:00" :
                                        return <Dropdown.Item eventKey="13:00">13:00</Dropdown.Item>
                                    case "14:00" :
                                        return <Dropdown.Item eventKey="14:00">14:00</Dropdown.Item>
                                    case "15:00" :
                                        return <Dropdown.Item eventKey="15:00">15:00</Dropdown.Item>
                                    case "16:00" :
                                        return <Dropdown.Item eventKey="16:00">16:00</Dropdown.Item>
                                    case "17:00" :
                                        return <Dropdown.Item eventKey="17:00">17:00</Dropdown.Item>
                                    case "18:00" :
                                        return <Dropdown.Item eventKey="18:00">18:00</Dropdown.Item>
                                    case "18:30" :
                                        return <Dropdown.Item eventKey="18:30">18:30</Dropdown.Item>
                                    case "19:00" :
                                        return <Dropdown.Item eventKey="19:00">19:00</Dropdown.Item>
                                    case "19:30" :
                                        return <Dropdown.Item eventKey="19:30">19:30</Dropdown.Item>
                                    case "20:00" : 
                                        return <Dropdown.Item eventKey="20:00">20:00</Dropdown.Item>
                                    case "20:30" : 
                                        return <Dropdown.Item eventKey="20:30">20:30</Dropdown.Item>
                                    default:
                                        return <Dropdown.Item eventKey="20:30">20:30</Dropdown.Item>
                                }
                            })}
                        </DropdownButton>
                    </div>
                    <p className='mr-2'>Reprogramare la data:</p>
                    <div className='d-flex flex-wrap mb-2'>
                        <DropdownButton onSelect={(e) => setToDay(e)} title={toDay === '' ? "Selectează ziua" : dayCode(toDay)} size="lg" variant="light">
                            <Dropdown.Item eventKey="">Niciuna</Dropdown.Item>
                            <Dropdown.Item eventKey="Lu">Luni</Dropdown.Item>
                            <Dropdown.Item eventKey="Ma">Marți</Dropdown.Item>
                            <Dropdown.Item eventKey="Mi">Miercuri</Dropdown.Item>
                            <Dropdown.Item eventKey="Jo">Joi</Dropdown.Item>
                            <Dropdown.Item eventKey="Vi">Vineri</Dropdown.Item>
                            <Dropdown.Item eventKey="Sa">Sâmbătă</Dropdown.Item>
                        </DropdownButton>
                        <DropdownButton onSelect={(e) => setToTime(e)} title={toTime === '' ? "Selectează ora " : toTime} size="lg" variant="light">
                            <Dropdown.Item eventKey="">Niciuna</Dropdown.Item>
                            {generateTimeOptions(toDay).map((toRe) => {
                                return <Dropdown.Item eventKey={toRe}>{toRe} - {toDay !== "" && dayDataByTime[toDay][toRe][child.membership] ? JSON.stringify(dayDataByTime[toDay][toRe][child.membership]) : 0}</Dropdown.Item>
                            })}
                        </DropdownButton>
                    </div>
                    <div className='w-100 d-flex'>
                        <p className='mr-2'>Data efectivă:</p>
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            placeholderText="zi/lună/an"
                            className="form-control animate__shakeX"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            yearDropdownItemNumber={100}
                            onFocus={(e) => e.target.readOnly = true}
                            selected={(date && new Date(date)) || null}
                            onChange={val => {
                                setDate(val);
                            }}
                            onYearChange={(date) => setDate(date)}
                            onMonthChange={(date) => setDate(date)}
                            onWeekSelect={(date) => setDate(date)}
                            onDayChange={(date) => setDate(date)}
                            openToDate={date}
                        />
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                        <TryButton text="Anulează" onClick={() => setShowReschedule(false)} className="mx-3"/>
                        <TryButton text="Reprogramează" className="greenButton" onClick={async () => {
                            let days = ["", "Lu", "Ma", "Mi", "Jo", "Vi", "Sa"];
                            if(days[date.getDay()] != toDay)
                                return alert("Data efectivă trebuie să fie în aceeași zi cu data de la reprogramare!");
                            if(date.getUTCMilliseconds() < moment().startOf('day').milliseconds())
                                return alert("Data efectivă trebuie să fie în viitor!");
                            
                            axios.patch(process.env.REACT_APP_API_URL + '/attendance/reschedule', {
                                id: child._id,
                                reschedule: fromDay + "," + fromTime + "-" + toDay + "," + toTime,
                                rescheduleDate: moment(date).utc(true).toDate()
                            },{
                                headers: {
                                    Authorization: `Bearer ${token}`
                                }
                            }).then((res) => {
                               alert(res.data);
                               setShowReschedule(false);
                            }).catch((err) => {
                                alert(err.response.data);
                                setShowReschedule(false);
                            });
                            await updateFunc();
                        }}/>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmare acțiune</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Sigur doriți să {actionText}?</p>
                    <div className="d-flex justify-content-end">
                        <TryButton text="Nu" onClick={() => setShowConfirmation(false)} className="mx-3"/>
                        <TryButton text="Da" className="greenButton" onClick={() => {action();}}/>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Edit Comments Modal */}
            <Modal show={showEditComments} onHide={() => setShowEditComments(false)}>
                <Modal.Header className='bg-black border border-danger' closeButton closeVariant='white'>
                    <h1 className='text-light'>Editează comentarii</h1>
                </Modal.Header>
                <Modal.Body className='bg-black border border-danger'>
                <Formik
                        initialValues={{ 
                            coachcomments: child.coachcomments,
                        }}
                        validate={values => {
                            const errors = {};
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            showLoadingScreen(3, () => {
                                setSubmitting(false);
                                values.id = child._id;
                                axios.patch(process.env.REACT_APP_API_URL + '/attendees/comments', values, {
                                        headers: {
                                    'Authorization': `Bearer ${token}`
                                    }
                                }).then((res) => {
                                    alert(res.data);
                                    updateFunc();   
                                }).catch((e) => {
                                    alert(e.response.data);
                                });
                                setShowEditComments(false);
                            });
                        }}
                    >
                        {({ isSubmitting }) => (
                            <Form className='m-auto w-100 attendee-form mt-3 px-2'>
                                <div className='mb-3'>
                                    <label htmlFor="coachcomments" className="form-label text-light">Comentarii antrenor</label>
                                    <Field type="text" name="coachcomments" placeholder = "Comentarii antrenor" className="form-control animate__shakeX" id="coachcomments"/>
                                    <ErrorMessage name="coachcomments" component="div" className="invalid-feedback" />
                                </div>

                                <TryButton type="submit" disabled={isSubmitting} className='mt-4 m-auto d-block' text="Editează"/>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>

            <Modal show={showEdit} onHide={() => setShowEdit(false)} backdrop="static">
                <Modal.Header className='bg-black border border-danger' closeButton closeVariant='white'>
                    <h1 className='text-light'>Editează copil</h1>
                </Modal.Header>
                <Modal.Body className='bg-black border border-danger'>
                    <Formik
                        initialValues={formValues}
                        validate={values => {
                            const errors = {};
                            const phoneNumberRegExp = /^07[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$/;
                            const emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                            const grupa = ['Lu', 'Ma', 'Mi', 'Jo', 'Vi', 'Sa'];
                            const ora = ['10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30'];
                            const nivel = ['INCEPATOR', 'INTERMEDIAR', 'SEMI-AVANSAT', 'AVANSAT', 'PERFORMANTA'];

                            if (!values.name) {
                                errors.name = 'Câmp obligatoriu!';
                            }
                            if (!values.birthday) {
                                errors.birthday = 'Câmp obligatoriu!';
                            }
                            if (!values.parentName) {
                                errors.parentName = 'Câmp obligatoriu!';
                            }
                            
                            if (!values.phoneNumber) {
                                errors.phoneNumber = 'Câmp obligatoriu!';
                            } else if(!values.phoneNumber.match(phoneNumberRegExp)) {
                                errors.phoneNumber = "Nr. de telefon invalid!";
                            }
                            if (!values.email) {
                                errors.email = 'Câmp obligatoriu!';
                            } else if(!values.email.match(emailRegExp)) {
                                errors.email = 'E-mail invalid!';
                            }
                            let bd = moment(values.birthday);
                            let bdp = moment(values.parentBirthday);
                            let bef = moment().subtract(18, 'years');
                            let aft = moment().subtract(3, 'years');

                            if(!bd)
                                errors.birthday = 'Zi de naștere invalidă!';
                            else if(bd.isBefore(bef))
                                errors.birthday = 'Copilul nu este minor!';
                            else if (!bd.isBefore(aft))
                                errors.birthday = 'Copilul este prea mic!';

                            if(!values.startDate)
                                errors.startDate = 'Câmp obligatoriu!';
                            if(!values.paymentStatus)
                                errors.paymentStatus = 'Câmp obligatoriu!';
                            if(!values.membership)
                                errors.membership = 'Câmp obligatoriu!';
                            if(!values.location)
                                errors.location = 'Câmp obligatoriu!';
                            if(!values.group)
                                errors.group = 'Câmp obligatoriu!';
                            if(!values.time)
                                errors.time = 'Câmp obligatoriu!';
                            if(!values.level)
                                errors.level = 'Câmp obligatoriu!';
                            if(JSON.stringify(values.attendanceNo) == "")
                                errors.attendanceNo = 'Câmp obligatoriu!';
                            if(values.attendanceNo < 0)
                                errors.attendanceNo = 'Valoare minima 0!';

                            if(values.paymentStatus.toUpperCase() != 'TBD' && values.paymentStatus.toUpperCase() != 'PARTIAL' && values.paymentStatus.toUpperCase() != 'FULL')
                                errors.paymentStatus = 'Introduceți tbd, partial sau full!';
                            
                            // Amount validation
                            if(values.paymentStatus.toUpperCase() == 'FULL' && child.paymentStatus.toUpperCase() == 'PARTIAL' && values.amount <= child.amount){
                                errors.amount = 'Suma trebuie să fie mai mare decat suma deja plătită!';
                            } 

                            if((values.paymentStatus.toUpperCase() == 'PARTIAL' || values.paymentStatus.toUpperCase() == 'FULL') && (!values.amount || values.amount < 40)){
                                errors.amount = 'Suma minimă este 40!';
                            }

                            if((values.paymentStatus.toUpperCase() === 'FULL' || values.paymentStatus.toUpperCase() === 'PARTIAL') && (!values.paymentType || values.paymentType.toUpperCase() === 'TBD')){
                                errors.paymentType = 'Alegeti obligatoriu metoda de plata!';
                            }

                            if(child.paymentStatus.toUpperCase() == 'FULL' && (values.amount != child.amount) && userType !== 'OWNER'){
                                errors.amount = 'Nu puteți modifica suma plătită!';
                            }

                            if(values.membership.toUpperCase() != 'TBD' && values.membership.toUpperCase() != 'GRUPA' && values.membership.toUpperCase() != 'MINI-GRUPA' && values.membership.toUpperCase() != 'PT' && values.membership.toUpperCase() != 'GRATIS')
                                errors.membership = 'Introduceți tbd, grupa, mini-grupa, gratis sau pt!';
                            if(values.location.toUpperCase() != 'TBD' && values.location.toUpperCase() != 'OTOPENI' && values.location.toUpperCase() != 'DOBROESTI' && values.location.toUpperCase() != 'FLOREASCA')
                                errors.location = 'Introduceți tbd, otopeni, dobroesti sau floreasca!';
                                
                            let groupValues = values.group.split('-');

                            if(!values.group.includes('-')) {
                                if(values.group.toUpperCase() != 'TBD' && !grupa.includes(values.group))
                                    errors.group = 'Zi invalidă! Zi validă: Lu, Ma, Mi, Jo, Vi, Sa!';
                            } else if(groupValues.length == 2) {

                                if(!grupa.includes(groupValues[0]))
                                    errors.group = 'Prima zi este invalidă! Zi validă: Lu, Ma, Mi, Jo, Vi, Sa!';
                                if(!grupa.includes(groupValues[1]))
                                    errors.group = 'A doua zi este invalidă! Zi validă: Lu, Ma, Mi, Jo, Vi, Sa!';
                            }  else if(groupValues.length == 3) {
                                if(!grupa.includes(groupValues[0]))
                                    errors.group = 'Prima zi este invalidă! Zi validă: Lu, Ma, Mi, Jo, Vi, Sa!';
                                if(!grupa.includes(groupValues[1]))
                                    errors.group = 'A doua zi este invalidă! Zi validă: Lu, Ma, Mi, Jo, Vi, Sa!';
                                if(!grupa.includes(groupValues[2]))
                                    errors.group = 'A treia zi este invalidă! Zi validă: Lu, Ma, Mi, Jo, Vi, Sa!';
                            } else if(groupValues.length == 4) {
                                if(!grupa.includes(groupValues[0]))
                                    errors.group = 'Prima zi este invalidă! Zi validă: Lu, Ma, Mi, Jo, Vi, Sa!';
                                if(!grupa.includes(groupValues[1]))
                                    errors.group = 'A doua zi este invalidă! Zi validă: Lu, Ma, Mi, Jo, Vi, Sa!';
                                if(!grupa.includes(groupValues[2]))
                                    errors.group = 'A treia zi este invalidă! Zi validă: Lu, Ma, Mi, Jo, Vi, Sa!';
                                if(!grupa.includes(groupValues[3]))
                                    errors.group = 'A patra zi este invalidă! Zi validă: Lu, Ma, Mi, Jo, Vi, Sa!';
                            } else if(groupValues.length == 5) {
                                if(!grupa.includes(groupValues[0]))
                                    errors.group = 'Prima zi este invalidă! Zi validă: Lu, Ma, Mi, Jo, Vi, Sa!';
                                if(!grupa.includes(groupValues[1]))
                                    errors.group = 'A doua zi este invalidă! Zi validă: Lu, Ma, Mi, Jo, Vi, Sa!';
                                if(!grupa.includes(groupValues[2]))
                                    errors.group = 'A treia zi este invalidă! Zi validă: Lu, Ma, Mi, Jo, Vi, Sa!';
                                if(!grupa.includes(groupValues[3]))
                                    errors.group = 'A patra zi este invalidă! Zi validă: Lu, Ma, Mi, Jo, Vi, Sa!';
                                if(!grupa.includes(groupValues[4]))
                                    errors.group = 'A cincea zi este invalidă! Zi validă: Lu, Ma, Mi, Jo, Vi, Sa!';
                            } else {
                                errors.group = 'Format invalid! Format corect: Lu, Ma, Mi, Jo, Vi, Sa!';
                            }

                            let timeValues = values.time.split('-');
                            if(!values.time.includes('-')) {
                                if(values.time.toUpperCase() != 'TBD' && !ora.includes(values.time))
                                    errors.time = 'Ora invalidă! Ora validă: 10:00, 11:00, 16:00, 17:00, 18:00, 18:30, 19:00, 19:30, 20:00, 20:30!';
                            } else if (groupValues.length == 2 && timeValues.length == 2) {
                                if(!ora.includes(timeValues[0]))
                                    errors.time = 'Prima ora este invalidă! Ora validă: 10:00, 11:00, 16:00, 17:00, 18:00, 18:30, 19:00, 19:30, 20:00, 20:30!';
                                if(!ora.includes(timeValues[1]))
                                    errors.time = 'A doua ora este invalidă! Ora validă: 10:00, 11:00, 16:00, 17:00, 18:00, 18:30, 19:00, 19:30, 20:00, 20:30!';
                            } else if (groupValues.length == 3 && (timeValues.length != 3)) {
                                errors.time = 'Trebuie sa introduceti 3 ore!';
                            } else if (groupValues.length == 4 && (timeValues.length != 4)) {
                                errors.time = 'Trebuie sa introduceti 4 ore!';
                            } else if (groupValues.length == 5 && (timeValues.length != 5)) {
                                errors.time = 'Trebuie sa introduceti 5 ore!';
                            } else if (groupValues.length == 3 && timeValues.length == 3) {
                                if(!ora.includes(timeValues[0]))
                                    errors.time = 'Prima ora este invalidă! Ora validă: 10:00, 11:00, 16:00, 17:00, 18:00, 18:30, 19:00, 19:30, 20:00, 20:30!';
                                if(!ora.includes(timeValues[1]))
                                    errors.time = 'A doua ora este invalidă! Ora validă: 10:00, 11:00, 16:00, 17:00, 18:00, 18:30, 19:00, 19:30, 20:00, 20:30!';
                                if(!ora.includes(timeValues[2]))
                                    errors.time = 'A treia ora este invalidă! Ora validă: 10:00, 11:00, 16:00, 17:00, 18:00, 18:30, 19:00, 19:30, 20:00, 20:30!';
                            } else if (groupValues.length == 4 && timeValues.length == 4) {
                                if(!ora.includes(timeValues[0]))
                                    errors.time = 'Prima ora este invalidă! Ora validă: 10:00, 11:00, 16:00, 17:00, 18:00, 19:00, 20:00!';
                                if(!ora.includes(timeValues[1]))
                                    errors.time = 'A doua ora este invalidă! Ora validă: 10:00, 11:00, 16:00, 17:00, 18:00, 19:00, 20:00!';
                                if(!ora.includes(timeValues[2]))
                                    errors.time = 'A treia ora este invalidă! Ora validă: 10:00, 11:00, 16:00, 17:00, 18:00, 19:00!';
                                if(!ora.includes(timeValues[3]))
                                    errors.time = 'A patra ora este invalidă! Ora validă: 10:00, 11:00, 16:00, 17:00, 18:00!';
                            } else if (groupValues.length == 5 && timeValues.length == 5) {
                                if(!ora.includes(timeValues[0]))
                                    errors.time = 'Prima ora este invalidă! Ora validă: 10:00, 11:00, 16:00, 17:00, 18:00!';
                                if(!ora.includes(timeValues[1]))
                                    errors.time = 'A doua ora este invalidă! Ora validă: 10:00, 11:00, 16:00, 17:00!';
                                if(!ora.includes(timeValues[2]))
                                    errors.time = 'A treia ora este invalidă! Ora validă: 10:00, 11:00!';
                                if(!ora.includes(timeValues[3]))
                                    errors.time = 'A patra ora este invalidă! Ora validă: 10:00!';
                                if(!ora.includes(timeValues[4]))
                                    errors.time = 'A cincea ora este invalidă! Ora validă: 11:00!';
                            } else {
                                errors.time = 'Format invalid! Format corect: 10:00-11:00, 16:00-17:00, 18:00-19:00, 20:00-21:00!';
                            }

                            if(values.level.toUpperCase() != 'TBD' && !nivel.includes(values.level.toUpperCase()))
                                errors.level = 'Nivel invalid! Nivel valid: Incepator, Intermediar, Semi-avansat, Avansat, Performanta!';
                            if(values.amount < 0)
                                errors.amount = 'Valoare minima 0!';

                            // If child become hidden, check if comments are modified (at least 3 characters)
                            if(values.hidden && values.hidden != child.hidden) {
                                if(Math.abs(values.comments.length - child.comments.length) < 3){
                                    errors.comments = 'Pentru a ascunde acest copil trebuie sa adaugi un comentariu nou!';
                                }
                            }

                            // If membership becomes "MINI-GRUPA" or "PT", there should be a coach comment
                            if((values.membership.toUpperCase() == 'MINI-GRUPA' || values.membership.toUpperCase() == 'PT') && values.coachcomments.length < 1) {
                                errors.coachcomments = 'Pentru mini-grupa sau pt trebuie sa adaugi un comentariu de antrenor!';
                            }

                            // let attendee = children.find((child) => child.name == values.name && child.parentName == values.parentName);
                            // if(attendee) {
                            //     errors.name = "Copilul deja există!";
                            //     errors.parentName = "Copilul deja există!";
                            // }
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            const startTime = Date.now();  // Start timer at form submission
                            //setShowLoading(true);  // Show loading screen
                            
                            setFormValues(values);
                            
                            // Check if there has been an edit beforehand
                            let edited = false;
                            axios.get(process.env.REACT_APP_API_URL + '/attendees/' + child._id, {
                                headers: { Authorization: `Bearer ${token}` }
                            })
                            .then((res) => {
                                const elapsedTime = Date.now() - startTime;  // Calculate request time
                                let newChild = res.data;
                        
                                for (let key in newChild) {
                                    if (newChild[key] !== child[key]) {
                                        console.log(key);
                                        edited = true;
                                        break;
                                    }
                                }
                        
                                if (!edited) {
                                    values.id = child._id;
                                    values.birthday = moment(values.birthday).toISOString();
                                    values.startDate = moment(values.startDate).toISOString();
                                    values.paymentStatus = values.paymentStatus.toUpperCase();
                                    values.membership = values.membership.toUpperCase();
                                    values.location = values.location.toUpperCase();
                                    values.level = values.level.toUpperCase();
                        
                                    if (values.paymentStatus === 'TBD') {
                                        values.amount = 0;
                                    }
                        
                                    if (values.paymentStatus === 'FULL' && values.amount !== child.amount) {
                                        // Verify if amount is discounted
                                        axios.post(process.env.REACT_APP_API_URL + '/payment/isDiscount', {
                                            location: values.location,
                                            membership: values.membership,
                                            attendanceNo: values.attendanceNo,
                                            amount: values.amount,
                                        }, {
                                            headers: { Authorization: `Bearer ${token}` }
                                        }).then((res) => {
                                            const discounted = res.data.discount;
                                            if (discounted) {
                                                setShowConfirmation(true);
                                                setActionText("confirmati suma de plata care pentru abonamentul selectat este deobicei mai mare, validati cursantul cu suma platita integral in valoare de " + values.amount);
                                                setAction(() => () => {editRequest(values); setShowConfirmation(false);});
                                            } else {
                                                editRequest(values);
                                            }
                                            //setTimeout(() => setShowLoading(false), elapsedTime);  // Hide loading after exact request time
                                        }).catch((e) => {
                                            alert(e.response.data);
                                            //setShowLoading(false);  // Stop loading screen on error
                                        });
                                    } else {
                                        editRequest(values);
                                        //setTimeout(() => setShowLoading(false), elapsedTime);  // Hide loading after exact request time
                                    }
                                } else {
                                    alert("Copilul a fost editat de altă persoană! Reîncărcați pagina și încercați din nou!");
                                    //setShowLoading(false);  // Stop loading screen on error
                                }
                            })
                            .catch((e) => {
                                alert(e.response.data);
                                //setShowLoading(false);  // Stop loading screen on error
                            });
                        
                            setSubmitting(false);
                        }}
                        
                    >
                        {({ values, isSubmitting }) => (
                            <Form className='m-auto w-100 attendee-form mt-3 px-2'>
                                <div className='mb-3'>
                                    <label htmlFor="name" className="form-label text-light">Nume copil</label>
                                    <Field type="text" name="name" placeholder = "Hatz Hatzulescu Jr." className="form-control animate__shakeX" id="name"/>
                                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                </div>

                                <div className='mb-3'>
                                    <label htmlFor="name" className="form-label text-light">Data nașterii copilului</label>
                                    <DatePickerField
                                        name='birthday'
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="zi/lună/an"
                                        className="form-control animate__shakeX"
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={18}
                                    />
                                    <ErrorMessage name="birthday" component="div" className="invalid-feedback" />
                                </div>
                                

                                <div className='mb-3'>
                                    <label htmlFor="parentName" className="form-label text-light">Nume părinte/tutore</label>
                                    <Field type="text" name="parentName" placeholder = "Hatz Hatzulescu" className="form-control animate__shakeX"/>
                                    <ErrorMessage name="parentName" component="div" className="invalid-feedback" />
                                </div>

                                <div className='mb-3'>
                                    <label htmlFor="phoneNumber" className="form-label text-light">Telefon părinte/tutore</label>
                                    <Field type="text" name="phoneNumber" placeholder = "Introduceți numărul de telefon" className="form-control animate__shakeX"/>
                                    <ErrorMessage name="phoneNumber" component="div" className="invalid-feedback" />
                                </div>

                                <div className='mb-3'>
                                    <label htmlFor="name" className="form-label text-light">E-mail părinte/tutore</label>
                                    <Field type="text" name="email" placeholder = "tryacademy@exemplu.com" className="form-control animate__shakeX"/>
                                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                </div>

                                <div className='mb-3'>
                                    <label htmlFor="startDate" className="form-label text-light">Data de început</label>
                                    <DatePickerField
                                        name='startDate'
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="zi/lună/an"
                                        className="form-control animate__shakeX"
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={18}
                                    />
                                    <ErrorMessage name="startDate" component="div" className="invalid-feedback" />
                                </div>

                                <div className='mb-3'>
                                    <label htmlFor="group" className="form-label text-light">Grupa</label>
                                    <Field type="text" name="group" placeholder = "Lu, Ma, ..." className="form-control animate__shakeX" id="group"/>
                                    <ErrorMessage name="group" component="div" className="invalid-feedback" />
                                </div>
                                
                                <div className='mb-3'>
                                    <label htmlFor="time" className="form-label text-light">Ora</label>
                                    <Field type="text" name="time" placeholder = "ex. 18:00" className="form-control animate__shakeX" id="time"/>
                                    <ErrorMessage name="time" component="div" className="invalid-feedback" />
                                </div>

                                <div className='mb-3'>
                                    <label htmlFor="membership" className="form-label text-light">Abonament</label>
                                    <Field type="text" name="membership" placeholder = "ex. grupa, mini-grupa, pt, gratis" className="form-control animate__shakeX" id="membership"/>
                                    <ErrorMessage name="membership" component="div" className="invalid-feedback" />
                                </div>

                                <div className='mb-3'>
                                    <label htmlFor="location" className="form-label text-light">Locație</label>
                                    <Field type="text" name="location" placeholder = "ex. Otopeni" className="form-control animate__shakeX" id="location"/>
                                    <ErrorMessage name="location" component="div" className="invalid-feedback" />
                                </div>

                                <div className='mb-3'>
                                    <label htmlFor="level" className="form-label text-light">Nivel</label>
                                    <Field type="text" name="level" placeholder = "ex. INCEPATOR" className="form-control animate__shakeX" id="level"/>
                                    <ErrorMessage name="level" component="div" className="invalid-feedback" />
                                </div>

                                <div className='mb-3'>
                                    <label htmlFor="attendanceNo" className="form-label text-light">Ședințe rămase</label>
                                    <Field type="text" name="attendanceNo" placeholder = "ex. 8" className="form-control animate__shakeX" id="attendanceNo"/>
                                    <ErrorMessage name="attendanceNo" component="div" className="invalid-feedback" />
                                </div>

                                <div className='mb-3'>
                                    <label htmlFor="paymentStatus" className="form-label text-light">Status plată</label>
                                    <Field type="text" name="paymentStatus" placeholder = "PARTIAL/FULL" className="form-control animate__shakeX" id="paymentStatus"/>
                                    <ErrorMessage name="paymentStatus" component="div" className="invalid-feedback" />
                                </div>

                                { values.paymentStatus.toUpperCase() != 'TBD' &&
                                    <div className='mb-3'>
                                        <label htmlFor="amount" className="form-label text-light">Sumă plată</label>
                                        <Field type="number" name="amount" placeholder="ex. 8" className="form-control animate__shakeX" id="amount"/>
                                        <ErrorMessage name="amount" component="div" className="invalid-feedback" />
                                    </div>
                                }

                                {values.paymentStatus.toUpperCase() !== 'TBD' && (
                                    <div className='mb-3'>
                                        <label htmlFor="paymentType" className="form-label text-light">Metodă de plată</label>
                                        <Field as="select" name="paymentType" className="form-control animate__shakeX" id="paymentType">
                                            <option value="TBD" selected>Alege metoda de plată</option>
                                            <option value="CASH">CASH</option>
                                            <option value="CARD">CARD</option>
                                            <option value="O.P.">O.P.</option>
                                        </Field>
                                        <ErrorMessage name="paymentType" component="div" className="invalid-feedback" />
                                    </div>
                                )}

                                <div className='mb-3'>
                                    <label htmlFor="comments" className="form-label text-light">Comentarii</label>
                                    <Field type="text" name="comments" placeholder = "Comentarii" className="form-control animate__shakeX" id="comments"/>
                                    <ErrorMessage name="comments" component="div" className="invalid-feedback" />
                                </div>

                                <div className='mb-3'>
                                    <label htmlFor="coachcomments" className="form-label text-light">Comentarii antrenor</label>
                                    <Field type="text" name="coachcomments" placeholder = "Comentarii antrenor" className="form-control animate__shakeX" id="coachcomments"/>
                                    <ErrorMessage name="coachcomments" component="div" className="invalid-feedback" />
                                </div>

                                <div className='mb-3'>
                                    <label>
                                        <Field type="checkbox" name="validated" />
                                        <span className="text-light ms-2">Copil validat</span>
                                    </label>
                                </div>

                                <div className='mb-3'>
                                    <label>
                                        <Field type="checkbox" name="hidden" />
                                        <span className="text-light ms-2">Copil ascuns</span>
                                    </label>
                                </div>

                                <TryButton type="submit" disabled={isSubmitting} className='mt-4 m-auto d-block' text="Editează"/>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </div>
);}

export default ChildPage;