import './AttendeeSignature.css';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import SignaturePad from 'react-signature-canvas';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import logo from '../images/Color_Badge.png';
import TryButton from '../button/TryButton';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import RulesOtopeni from './RulesOtopeni';
import RulesDobroesti from './RulesDobroesti';
import RulesFloreasca from './RulesFloreasca';

function AttendeeSignature() {
    const [ checkCount, setCheckCount ] = useState(0);
    const [ errors, setErrors ] = useState("");
    const [ show, setShow ] = useState(false);
    const [ confirmationShow, setConfirmationShow ] = useState(false);
    const { childLocation } = useParams();
    const location = useLocation();
    let sigCanvas = useRef({});
    const navigate = useNavigate();

    useEffect(() => {
        if(location.state == null || location.state.children == null || location.state.parentName == null || location.state.parentBirthday == null || location.state.phoneNumber == null || location.state.email == null) {
            navigate('/form');
        }
        //console.log(childLocation.toLowerCase());
    }, [checkCount]);

    function checkCounter(value) {
        if(value) {
            setCheckCount(checkCount + 1);
            setErrors("");
        }
        else
            setCheckCount(checkCount - 1);
    }

    function clearSignature() {
        sigCanvas.current.clear();
    }
    async function sendSignature() {
        if(checkCount != 10) {
            setErrors("Acceptati toate cerintele!");
            return;
        }
        const signature = sigCanvas.current.toDataURL();
        let data = {
            children: location.state.children,
            parentName: location.state.parentName,
            parentBirthday: moment(location.state.parentBirthday).toISOString(),
            phoneNumber: location.state.phoneNumber,
            email: location.state.email,
            location: childLocation,
            signature: signature
        };
        axios.post(process.env.REACT_APP_API_URL + "/attendees/", data).then(() => {
            setShow(false);
            setConfirmationShow(true);
            console.log("Done");
        }).catch((e) => {
            console.log(e);
        })
    }
    return (
        <>
            <img src={logo} alt="logo" className="logo-circle"/>
            <h4 className='mt-3 mx-2 text-center text-danger fw-bold fst-italic'>Citiți cu atenție și bifați fiecare căsuță corespunzătoare articolelor de mai jos!</h4>
            <h1 className='mt-4 mx-2 text-center text-light'>ACORD DE PARTICIPARE</h1>
            <h1 className='m-auto mx-2 text-center text-light'>TRY Academy - Mitzuu Media SRL</h1>
            <div className='mt-5 mx-2'>
                <h4 className='ms-5 me-4 text-danger fw-bold fst-italic'>În calitate de participant/reprezentant al participantului la toate/oricare dintre activitățile organizate de MITZUU MEDIA S.R.L. (denumită, în continuare, TRY Academy sau MITZUU MEDIA SRL), prin prezentul Acord, îmi asum și declar următoarele:</h4>
                { (childLocation.toLowerCase() == "otopeni" || childLocation.toLowerCase() == "tbd") &&
                    <RulesOtopeni checkCounter={checkCounter}/>
                }
                { childLocation.toLowerCase() == "dobroesti" &&
                    <RulesDobroesti checkCounter={checkCounter}/>
                }
                { childLocation.toLowerCase() == "floreasca" &&
                    <RulesFloreasca checkCounter={checkCounter}/>
                }
                <div>
                    <p className='m-auto text-center text-light mb-3'>Semnați aici!</p>
                    <SignaturePad penColor='black' canvasProps={{className: 'border m-auto d-block m-top signature'}} ref={sigCanvas} />
                </div>
                <p className='m-auto text-center red mt-3'>
                    {errors}
                </p>
                <div>
                    <TryButton onClick={() => clearSignature()} className='m-auto mx-2 d-inline custom-button' text='Șterge semnătura'/>
                    <TryButton onClick={() => setShow(true)} className='m-auto mx-2 mt-2 mb-4 d-inline custom-button greenButton' text='Semnează'/>
                </div>
                <Modal show={show} onHide={() => setShow(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Trimitere date</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Sigur doriți să trimiteți datele?</p>
                        <div className="d-flex justify-content-end">
                            <TryButton text="Nu" onClick={() => setShow(false)} className="mx-3"/>
                            <TryButton text="Da" onClick={() => sendSignature()} className="greenButton"/>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={confirmationShow} onHide={() => { setConfirmationShow(false); navigate('/'); }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Trimitere date</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Înregistrarea a fost efectuată. Acordul semnat a fost trimis pe e-mail.</p>
                        <div className="d-flex justify-content-end">
                            <TryButton text="Ok" onClick={() => { setConfirmationShow(false); navigate('/'); }} className="greenButton"/>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
}

export default AttendeeSignature;