import './AttendeeForm.css';
import '../../App.css';
import TryButton from '../button/TryButton';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import logo from "../images/logo_text.png";
import { DatePickerField } from './DatePickerField';
import { Modal } from 'react-bootstrap';
import moment from 'moment';

function AttendeeSignature() {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const { childLocation } = useParams();
    const correctLocations = ["OTOPENI", "DOBROESTI", "FLOREASCA", "TBD"];
    const [childLocationFinal, setChildLocationFinal] = useState("");

    useEffect(() => {
        // if(children.length < 1)
        //     axios.get("http://localhost:5000/attend/all").then(res => {
        //         setChildren(res.data);
        //     });
        if(childLocation == undefined)
            navigate('/');
        else if (!correctLocations.includes(childLocation.toUpperCase()))
            navigate('/');
        else
            setChildLocationFinal(correctLocations[correctLocations.indexOf(childLocation.toUpperCase())]);
    }, []);

    function addChildField(setFieldValue, children) {
        children.push({name: '', birthday: ''});

        setFieldValue('children', children);
    }

    return (
        <div className="page no-padding-top">
            <img src={logo} className="logo"/>
            <h1 className="text-center mb-md-5 mt-3 fw-bold fst-italic">Formular de înscriere</h1>
            <Formik
                initialValues={{ 
                    children: [{name: '', birthday: ''}],
                    parentName: '',
                    parentBirthday: '',
                    phoneNumber: '',
                    email: ''
                }}
                validate={values => {
                    const errors = {};
                    const phoneNumberRegExp = /^07[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$/;
                    const emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                    if(!values.children || values.children.length == 0)
                        errors.children = "Adăugați cel puțin un copil!";
                    else {
                        errors.children = values.children.map(() => ([[]]));
                        values.children.forEach((child, index) => {
                            if (!child.name) {
                                errors.children[index][0].push('name');
                                errors.children[index].push('Câmp obligatoriu!');
                            } else if (child.name.trim().split(" ").length < 2) {
                                errors.children[index][0].push('name');
                                errors.children[index].push('Introduceți și numele și prenumele!');
                            }
                            if (!child.birthday) {
                                errors.children[index][0].push('birthday');
                                errors.children[index].push('Câmp obligatoriu!');
                            } else {
                                let bd = moment(child.birthday);
                                let aft = moment().subtract(2, 'years');
                                let bef = moment().subtract(18, 'years');
            
                                if(!bd) {
                                    errors.children[index][0].push('birthday');
                                    errors.children[index].push('Zi de naștere invalidă!');
                                } else if(bd.isBefore(bef)) {
                                    errors.children[index][0].push('birthday');
                                    errors.children[index].push('Copilul nu este minor!');
                                } else if (!bd.isBefore(aft)) {
                                    errors.children[index][0].push('birthday');
                                    errors.children[index].push('Copilul este prea mic!');
                                }
                            }
                        });
                        let hasChildrenErrors = false;
                        if(errors.children && errors.children.length > 0)
                            errors.children.forEach((child) => {
                                if(child.length > 1)
                                    hasChildrenErrors = true;
                            });
                        if(!hasChildrenErrors)
                            delete errors.children;
                    }

                    if (!values.parentName) {
                        errors.parentName = 'Câmp obligatoriu!';
                    } else if (values.parentName.trim().split(" ").length < 2) {
                        errors.parentName = 'Introduceți și numele și prenumele!';
                    }
                    if (!values.parentBirthday) {
                        errors.parentBirthday = 'Câmp obligatoriu!';
                    }
                    if (!values.phoneNumber) {
                        errors.phoneNumber = 'Câmp obligatoriu!';
                    } else if(!values.phoneNumber.match(phoneNumberRegExp)) {
                        errors.phoneNumber = "Nr. de telefon invalid!";
                    }
                    if (!values.email) {
                        errors.email = 'Câmp obligatoriu!';
                    } else if(!values.email.match(emailRegExp)) {
                        errors.email = 'E-mail invalid!';
                    }

                    let bdp = moment(values.parentBirthday);
                    let bef = moment().subtract(18, 'years');
                    if(!bdp)
                        errors.parentBirthday = 'Zi de naștere invalidă!';
                    else if(bdp.isAfter(bef))
                        errors.parentBirthday = 'Părintele nu este major!';
                    
                    // let attendee = children.find((child) => child.name.toLowerCase() == values.name.toLowerCase() && child.parentName.toLowerCase() == values.parentName.toLowerCase());
                    // if(attendee) {
                    //     errors.name = "Copilul deja există!";
                    //     errors.parentName = "Copilul deja există!";
                    // }
                    console.log(errors);
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                    axios.post(process.env.REACT_APP_API_URL + '/attendees/exists', {
                        children: values.children.map((child) => ({name: child.name, birthday: moment(child.birthday).toISOString()})),
                        parentName: values.parentName,
                    }).then((res) => {
                        console.log(childLocationFinal);
                        navigate('/sign/' + childLocationFinal, { state: {
                            children: values.children.map((child) => ({name: child.name.trim(), birthday: moment(child.birthday).toISOString()})),
                            parentName: values.parentName.trim(),
                            parentBirthday: moment(values.parentBirthday).toISOString(),
                            phoneNumber: values.phoneNumber,
                            email: values.email
                        }});
                    }).catch((err) => {
                        alert(err.response.data);
                    });
                }}
            >
                {({ values, errors, setFieldValue, submitForm, isSubmitting }) => (
                    <Form className='m-auto attendee-form mt-md-5 mt-3'>
                        {/* <Row className='justify-content-around my-md-5'>
                            <Col sm={12} md={5} className='text-start px-5 my-2 my-md-0'>
                                <label htmlFor="name" className="form-label text-light">Nume copil</label>
                                <Field type="text" name="name" placeholder = "Hatz Hatzulescu Jr." className="form-control animate__shakeX" id="name"/>
                                <ErrorMessage name="name" component="div" className="invalid-feedback" />
                            </Col>
                            <Col sm={12} md={5} className='text-start px-5 my-2 my-md-0'>
                                <label htmlFor="name" className="form-label text-light">Data nașterii copilului</label>
                                <DatePickerField
                                    name='birthday'
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="zi/lună/an"
                                    className="form-control animate__shakeX"
                                    showYearDropdown
                                    scrollableYearDropdown
                                    yearDropdownItemNumber={18}
                                />
                                <ErrorMessage name="birthday" component="div" className="invalid-feedback" />
                            </Col>


                        </Row> */}
                        <Row className='justify-content-around my-md-5'>
                            <Col sm={12} md={5} className='text-start px-5 my-2 my-md-0'>
                                <label htmlFor="name" className="form-label text-light text-truncate">Nume și prenume tutore</label>
                                <Field type="text" name="parentName" placeholder = "Introduceti numele parintelui" className="form-control animate__shakeX"/>
                                <ErrorMessage name="parentName" component="div" className="invalid-feedback" />
                            </Col>
                            <Col sm={12} md={5} className='text-start px-5 my-2 my-md-0'>
                                <label htmlFor="name" className="form-label text-light text-truncate">Data nașterii tutorelui</label>
                                <DatePickerField
                                    name='parentBirthday'
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="zi/lună/an"
                                    className="form-control animate__shakeX"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    yearDropdownItemNumber={100}
                                    onFocus={(e) => e.target.readOnly = true}
                                    shouldCloseOnSelect={false}
                                    //closeOnScroll={true}
                                />
                                <ErrorMessage name="parentBirthday" component="div" className="invalid-feedback" />
                            </Col>
                        </Row>
                        <Row className='justify-content-around my-md-5'>
                            <Col sm={12} md={5} className='text-start px-5 my-2 my-md-0'>
                                <label htmlFor="name" className="form-label text-light text-truncate">Telefon tutore</label>
                                <Field type="text" name="phoneNumber" placeholder = "Introduceți numărul de telefon" className="form-control animate__shakeX"/>
                                <ErrorMessage name="phoneNumber" component="div" className="invalid-feedback" />
                            </Col>
                            <Col sm={12} md={5} className='text-start px-5 my-2 my-md-0'>
                                <label htmlFor="name" className="form-label text-light text-truncate">E-mail tutore</label>
                                <Field type="text" name="email" placeholder = "tryacademy@exemplu.com" className="form-control animate__shakeX"/>
                                <ErrorMessage name="email" component="div" className="invalid-feedback" />
                            </Col>
                        </Row>

                        {/* Initial child fields */}
            {Array.from({ length: values.children.length }).map((_, index) => (
              <React.Fragment key={index}>
                <Row className='justify-content-around mt-md-5 mb-md-3'>
                  <Col sm={12} md={5} className='text-start px-5 my-2 my-md-0'>
                    <label htmlFor={`name${index}`} className="form-label text-light text-truncate">Nume și prenume copil</label>
                    <Field
                      type="text"
                      name={`children[${index}].name`}
                      placeholder="Introduceti numele copilului"
                      className="form-control animate__shakeX"
                      id={`name${index}`}
                    />
                    <ErrorMessage name="children" component="div" className="invalid-feedback"  render={(msg) => {
                        if(msg && typeof msg === "object" && msg.length > index && msg[index] && msg[index].length > 0 && msg[index][0] && msg[index][0].length > 0) {
                            let ind = msg[index][0].indexOf('name');
                            
                            if(ind != -1)
                                return <div className="invalid-feedback">{msg[index][ind + 1]}</div>;
                            else
                                return <div className="invalid-feedback"></div>;
                        } else return <div className="invalid-feedback"></div>;
                    }}/>
                  </Col>
                  <Col sm={12} md={5} className='text-start px-5 my-2 my-md-0'>
                    <label htmlFor={`childBirthday${index}`} className="form-label text-light text-truncate">Data nașterii copilului</label>
                    <DatePickerField
                        name= {`children[${index}].birthday`}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="zi/lună/an"
                        className="form-control animate__shakeX"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        yearDropdownItemNumber={100}
                        onFocus={(e) => e.target.readOnly = true}
                        shouldCloseOnSelect={false}
                        //closeOnScroll={true}
                    />
                    <ErrorMessage name="children" component="div" className="invalid-feedback"  render={(msg) => {
                        if(msg && typeof msg === "object" && msg.length > index && msg[index] && msg[index].length > 0 && msg[index][0] && msg[index][0].length > 0) {
                            let ind = msg[index][0].indexOf('birthday');
                            if(ind != -1)
                                return <div className="invalid-feedback">{msg[index][ind + 1]}</div>;
                            else
                                return <div className="invalid-feedback"></div>;
                        } else return <div className="invalid-feedback"></div>;
                    }}/>
                  </Col>
                </Row>
                {index > 0 &&
                    <TryButton type="button" className='m-auto d-block' onClick={() => {values.children.splice(index, 1); setFieldValue('children', values.children);}} text="Șterge copil"/>
                }
              </React.Fragment>
            ))}
                    <ErrorMessage name="children" component="div" render={() => <div className="invalid-feedback">{typeof errors.children === "string" ? errors.children : ""}</div>} className="invalid-feedback" />
                    {/* Button to add child field */}
                    <Row className='justify-content-center mt-3'>
                        <Col md = {3}>
                            <TryButton
                                type="button"
                                className='my-3 m-auto d-block my-custom-button'
                                onClick={() => addChildField(setFieldValue, values.children)}
                                text="Adauga copil / frate"
                            />
                        </Col>
                        <Col md = {3}>
                            <TryButton type="button" className='my-3 m-auto d-block greenButton' onClick={() => setShow(true)} text="Continuare"/>                                
                        </Col>
                    </Row>
                    <Modal show={show} onHide={() => setShow(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Continuare formular</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Sigur doriți să continuați?</p>
                            <div className="d-flex justify-content-end">
                                <TryButton type="button" text="Nu" onClick={() => setShow(false)} className="mx-3"/>
                                <TryButton type="submit" disabled={isSubmitting} onClick={() => { setShow(false); submitForm(); }} text="Da" className="greenButton"/>
                            </div>
                        </Modal.Body>
                    </Modal>
                </Form>
                )}
            </Formik>
        </div>
    );
}

export default AttendeeSignature;